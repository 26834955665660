import { Directive, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';

@UntilDestroy()
@Directive({
    selector: '[matOptionsScroll]',
    standalone: true
})
export class OptionsScrollDirective implements OnInit, OnDestroy {
    @Output('optionsScroll') scroll = new EventEmitter<Event>();

    get panelOpen(): Observable<void> {
        return this.matAutocomplete ? this.matAutocomplete.opened : this.matSelect._openedStream;
    }

    get panelClose(): Observable<void> {
        return this.matAutocomplete ? this.matAutocomplete.closed : this.matSelect._closedStream;
    }

    get panelEl(): ElementRef<any> {
        return this.matAutocomplete ? this.matAutocomplete.panel : this.matSelect.panel;
    }

    constructor(
        @Optional() @Inject(MatAutocomplete) public matAutocomplete: MatAutocomplete,
        @Optional() @Inject(MatSelect) public matSelect: MatSelect,
    ) {
    }

    ngOnInit(): void {
        this.panelOpen.pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            setTimeout(() => {
                this.removeScrollEventListener();
                if (this.panelEl?.nativeElement) {
                    this.panelEl.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
                }
            });
        });

        this.panelClose.pipe(
            untilDestroyed(this)
        ).subscribe(() => this.removeScrollEventListener());
    }

    private removeScrollEventListener() {
        if (this.panelEl?.nativeElement) {
            this.panelEl.nativeElement.removeEventListener('scroll', this.onScroll);
        }
    }

    ngOnDestroy() {
        this.removeScrollEventListener();
    }

    onScroll(event: Event) {
        this.scroll.next(event);
    }
}
