import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { VacancyApplication } from '@app/models/hr/vacancy.model';
import { AccountUserProfile } from '@app/models/users/account-user-profile.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { UserType } from '@app/models/users/user-profile.model';
import { UserIfc } from '@app/models/users/user.ifc';
import { Applicant, GetApplicantsRequestParams } from "@app/models/applicants/applicant.model";

@Injectable({
  providedIn: 'root'
})
export class ApplicantsService {

  constructor(
    private http: HttpService
  ) { }

  deleteApplicant(id: number): Observable<any> {
    return <Observable<any>>this.http.DELETE(`${ URLS.application }${ id }/`);
  }

  addApplicantToVacancy(data: VacancyApplication): Observable<VacancyApplication> {
    return <Observable<VacancyApplication>>this.http
    .POST(`${ URLS.application }`, data);
  }

  getApplicationsByVacancyId(vacancyId: number): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(`${ URLS.application }?vacancy=${ vacancyId }&limit=1000`);
  }

  createApplicant(data: AccountUserProfile): Observable<AccountUserProfile> {
    return this.http.POST<AccountUserProfile>(`${ URLS.hr_profile }`, data);
  }

  changeApplicationStatus(applicationId: number, data: VacancyApplication): Observable<VacancyApplication> {
    return this.http.PUT<VacancyApplication>(`${ URLS.application }${ applicationId }/`, data);
  }

  getApplicationApplicantList(params: string): Observable<PaginatedResponse> {
    return this.http.GET<PaginatedResponse>(`${ URLS.application }?${ params }`);
  }

  getApplicants(params?: GetApplicantsRequestParams): Observable<PaginatedResponse<Applicant>> {
    return this.http.GET<PaginatedResponse<Applicant>>(URLS.hr_profile, params);
  }

  deleteProfileApplicant(id: number): Observable<any> {
    return this.http.DELETE<any>(`${ URLS.hr_profile }${ id }/`);
  }

  getApplicantVacancies(url: string): Observable<PaginatedResponse> {
    return this.http.GET<PaginatedResponse>(url);
  }

  getInterviewersList(limit = 1000): Observable<PaginatedResponse> {
    return this.http.GET<PaginatedResponse>(`${ URLS.profile }?profile_types=${ UserType.HR }&lookup_field=full_name&limit=${limit}`);
  }

  getOfficesList(limit = 1000): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.GET(`${ URLS.office }?limit=${limit}`);
  }

  getVacanciesList(limit = 1000): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.GET(`${ URLS.vacancy }?limit=${limit}`);
  }
}
