import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { mergeWithPaginatedResponse, toPaginatedResponse } from '@app/core/rx-operators';
import { inject } from '@angular/core';
import { IdNameCodeWidgetResponse } from '@app/models/response.model';

export class AnimalsOptionsLoader implements OptionsLoader {
  private httpService = inject(HttpService);

  constructor(
    private additionalOptions?: IdNameCodeWidgetResponse[]
  ) {}

  getOptions(): Observable<PaginatedResponse<IdNameCodeWidgetResponse>> {
    return this.httpService.GET<PaginatedResponse<IdNameCodeWidgetResponse>>(URLS.animal).pipe(
      toPaginatedResponse(),
      mergeWithPaginatedResponse(this.additionalOptions)
    );
  }
}
