import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Translation, QueryParamsTranslation } from '@app/pages/language-settings/models/translation.model';
import { URLS } from './urls';
export class CustomTranslateLoader implements TranslateLoader {
    constructor(
      private http: HttpClient,
      private queryParams: QueryParamsTranslation
    ) {}
  
    getTranslation(lang: string): Observable<any> {
      const url = `${ URLS.translation }retrieve_language/${ lang }/`;
      const httpParams = new HttpParams({
        fromObject: {
          ...this.queryParams,
        },
      });
  
      return this.http.get(url, { params: httpParams }).pipe(
        map((response: Translation) => {
          return response.language_data || {}
        })
      );
    }
  }