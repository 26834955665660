import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services';
import { Company } from '@app/models/company.model';
import { Office } from '@app/models/settings/offices/office.model';

import { URLS } from '@app/shared/urls';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsSection } from '@app/models/settings/settings-section';
import { filter, tap } from 'rxjs/operators';
import { ReferenceTableItem, ReferenceTableRequestParams } from '@app/models/settings/reference-table.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public company$: Observable<Company>;

  private companySubject: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);

  constructor(
    private http: HttpService
  ) {
    this.company$ = this.companySubject.asObservable().pipe(filter((company) => !!company));
  }

  getCompany(companyId: number): Observable<Company> {
    return this.http.GET<Company>(`${ URLS.company }${ companyId }/`).pipe(
      tap((company) => this.companySubject.next(company))
    );
  }

  updateCompany(companyId: number, payload): Observable<Company> {
    return this.http.PUT<Company>(`${ URLS.company }${ companyId }/`, payload).pipe(
      tap((company) => this.companySubject.next(company))
    );
  }

  addOffice(office: Partial<Office>): Observable<Office> {
    return this.http.POST<Office>(`${ URLS.office }`, office);
  }

  editOffice(office: Partial<Office>): Observable<Office> {
    return this.http.PUT<Office>(`${ URLS.office }${ office.id }/`, office);
  }

  getSettingsSection(): Observable<SettingsSection> {
    return this.http.GET<SettingsSection>(URLS.settings);
  }

  updateSettingsSection(settings: Partial<SettingsSection>): Observable<void> {
    return this.http.PUT<void>(`${ URLS.settings }bulk_update/`, settings);
  }

  getReferenceTable(params: ReferenceTableRequestParams): Observable<PaginatedResponse<ReferenceTableItem> | ReferenceTableItem[]> {
    return this.http.GET<PaginatedResponse<ReferenceTableItem> | ReferenceTableItem[]>(`${ URLS.reference_table }`, params);
  }

  createReferenceTableItem(item: ReferenceTableItem): Observable<ReferenceTableItem> {
    return this.http.POST<ReferenceTableItem>(`${ URLS.reference_table }`, item);
  }

  updateReferenceTableItem(item: ReferenceTableItem): Observable<ReferenceTableItem> {
    return this.http.PUT<ReferenceTableItem>(`${ URLS.reference_table }${ item.id }/`, item);
  }

  deleteReferenceTableItem(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.reference_table }${ id }/`);
  }
}
