import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { QueryParamsTranslation, Translation } from '../../pages/language-settings/models/translation.model';
import { TranslationString, QueryParamsTranslationString } from '../../pages/language-settings/models/translation-string.model';
import { Context } from '../../pages/language-settings/models/context.model';
import { LanguageModel } from '../../pages/language-settings/models/language.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageSettingsService {

  constructor(
    private http: HttpService
  ) { }
  
  getTranslationById(id: number): Observable<PaginatedResponse<Translation>> {
    return this.http.GET<PaginatedResponse<Translation>>(`${ URLS.translation }${ id }/`);
  }

  putTranslation(id: number, data: Translation): Observable<Translation> {
    return this.http.PUT<Translation>(`${ URLS.translation }${ id }/`, data);
  }

  getTranslations(queryParams?: QueryParamsTranslation): Observable<Translation[]> {
    return this.http.GET<Translation[]>(`${ URLS.translation }`, queryParams);
  }

  getLanguages(): Observable<LanguageModel[]> {
    return this.http.GET<LanguageModel[]>(`${ URLS.translation }languages/`);
  }

  addLanguage(data: Translation): Observable<Translation> {
    return this.http.POST<Translation>(`${ URLS.translation }`, data);
  }

  addLanguageBulkCreate(data: Translation[]): Observable<LanguageModel[]> {
    return this.http.POST<Translation[]>(`${ URLS.translation }bulk_create/`, data);
  }

  setAsDefault(id: number): Observable<{ status: boolean }> {
    return this.http.POST<{ status: boolean }>(`${ URLS.translation }${ id }/set_default/`);
  }

  getTranslationString(queryParams?: QueryParamsTranslationString): Observable<PaginatedResponse<TranslationString[]>> {
    return this.http.GET<PaginatedResponse<TranslationString[]>>(`${ URLS.translation_string }`, queryParams);
  }

  getTranslationStringContext(): Observable<Context[]> {
    return this.http.GET<Context[]>(`${ URLS.translation_string }context_areas/`);
  }

  putTranslationString(id: number, data: { translation_strings: TranslationString[] }): Observable<PaginatedResponse<TranslationString[]>> {
    return this.http.PUT<PaginatedResponse<TranslationString[]>>(`${ URLS.translation }${ id }/bulk_update_strings/`, data);
  }
}
