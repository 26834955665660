import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { DictionariesService } from '@app/core/services/dictionaries.service';
import { IdValueWidgetResponse } from '@app/models/response.model';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';

export class CountriesOptionsLoader implements OptionsLoader {
  private dictionariesService = inject(DictionariesService);

  getOptions(params?: object): Observable<PaginatedResponse<IdValueWidgetResponse>> {
    return this.dictionariesService.getDictionary({
      dictionary: 'country',
      widget: 'fk',
      paginate: true,
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
