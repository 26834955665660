import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  AuthorizationDetails,
  AuthorizationRequestParams,
  BlastingAuthorization,
  PatientAuthorization,
  PatientAuthorizationRequestParams,
  PatientCalendarVisit
} from '@app/models/patient/authorizations.model';
import { Patient, PatientAttentions, PatientsRequestParams } from '@app/models/patient/patient.model';
import { EditPatientData } from '@app/models/patient/edit-patient-data.model';
import { PatientHistory } from '@app/models/patient/history.model';
import { PaginationParams } from '@app/shared/interfaces/pagination.class';
import { PatientNote, PatientNoteCreationParams, PatientNoteRequestParams } from '@app/models/patient/notes.model';
import { Profile } from '@app/core/services/auth/profile.token';
import { AuthProfileInfo } from '@app/models/users/user-profile.model';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  public patient$ = new BehaviorSubject<EditPatientData>(null);

  constructor(
    private http: HttpService,
    @Inject(Profile) private profile: AuthProfileInfo,
  ) { }

  getPatients(params: PatientsRequestParams): Observable<PaginatedResponse<Patient> | Patient[]> {
    return this.http.GET<PaginatedResponse<Patient> | Patient[]>(URLS.patient, params);
  }

  getPatientById(patientId: number): Observable<EditPatientData> {
    return this.http.GET<EditPatientData>(`${ URLS.patient }${ patientId }/`);
  }

  updatePatient(patientId: number, data: EditPatientData): Observable<EditPatientData> {
    return this.http.PUT<EditPatientData>(`${ URLS.patient }${ patientId }/`, data).pipe(
      tap((data: EditPatientData) => {
        if (data.id === this.patient$.value.id) {
          this.patient$.next(data);
        }
      })
    );
  }

  getAuthorizationList(params: object): Observable<PaginatedResponse<AuthorizationDetails>> {
    return this.http.GET<PaginatedResponse<AuthorizationDetails>>(`${ URLS.payer_authorization }`, params);
  }

  getBlastingAuthorizations(params: AuthorizationRequestParams): Observable<PaginatedResponse<BlastingAuthorization>> {
    return this.http.GET<PaginatedResponse<BlastingAuthorization>>( URLS.payer_authorization, {
      ...params,
      used_by_master_week: true,
      blasting_info: true,
    });
  }

  getAttentions(patientId: number): Observable<PatientAttentions> {
    return this.http.GET<PatientAttentions>(`${ URLS.patient }${ patientId }/attentions/`);
  }

  getAuthorizationById(authId: number, params?: object): Observable<AuthorizationDetails> {
    return this.http.GET<AuthorizationDetails>(`${ URLS.payer_authorization }${ authId }/`, params);
  }

  deleteAuthorizationById(authId: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.payer_authorization }${ authId }/`);
  }

  createPatient(data): Observable<any> {
    return this.http.POST<any>(URLS.patient, data);
  }

  getPatientAuthorizationsById(patientId: number, params?: PatientAuthorizationRequestParams): Observable<PatientAuthorization[]> {
    return this.http.GET<PatientAuthorization[]>(`${ URLS.patient_calendar }${ patientId }/authorizations/`, params);
  }

  getPatientCalendarVisits(patientId: number, params?: any): Observable<PatientCalendarVisit[]> {
    return this.http.GET<PatientCalendarVisit[]>(`${ URLS.patient_calendar }${ patientId }/`, params);
  }

  getHistory(patientId: number, params?: PaginationParams): Observable<PaginatedResponse<PatientHistory>> {
    return this.http.GET<PaginatedResponse<PatientHistory>>(`${ URLS.history }patient/${ patientId }/`, params);
  }

  getNotes(params: PatientNoteRequestParams): Observable<PaginatedResponse<PatientNote>> {
    const contentType = this.profile.contentTypes.patient;

    return this.http.GET<PaginatedResponse<PatientNote>>(URLS.note, { ct: contentType, ...params });
  }

  createNote(data: PatientNoteCreationParams): Observable<PatientNote> {
    const contentType = this.profile.contentTypes.patient;

    return this.http.POST<PatientNote>(URLS.note, {
      content_type: contentType,
      ...data,
    });
  }

  editNote(note: PatientNote & PatientNoteCreationParams): Observable<PatientNote> {
    const contentType = this.profile.contentTypes.patient;

    return this.http.PUT<PatientNote>(`${ URLS.note }${ note.id }/`, {
      content_type: contentType,
      ...note,
    });
  }

  deleteNote(noteId): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.note }${ noteId }/`);
  }
}
