import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { PayerBatchesService } from '../services';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { ServiceWidgetWithUniqueSelector } from '@app/models/service.model';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { map } from 'rxjs/operators';

interface ServiceItem extends ServiceWidgetWithUniqueSelector {
  ids_string: string;
}

export class PayerServicesOptionsLoader implements OptionsLoader {
  private payerBatchesService = inject(PayerBatchesService);

  getOptions(params?: { payers: number[] }): Observable<PaginatedResponse<ServiceItem>> {
    return this.payerBatchesService.getPayerServices(params).pipe(
        toPaginatedResponse(),
        map((response: PaginatedResponse<ServiceWidgetWithUniqueSelector>) => {
          return { ...response, results: response.results.map(item => ({ ...item, ids_string: item.ids.join(',') })) };
        })
      );
  }
}
