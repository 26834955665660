import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NumberFieldComponent } from '@app/shared/fields/number-field/number-field.component';
import { OnlyNumberModule } from '@app/shared/directives/only-number/only-number.module';

@NgModule({
  imports: [
    CommonModule,
    OnlyNumberModule,
  ],
  declarations: [
    NumberFieldComponent,
  ],
  exports: [
    NumberFieldComponent,
  ]
})
export class NumberFieldModule {
}
