import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PaginatedResponse } from '@app/models/paginated-response.model';
import { Message, MessagePayload } from '../models/message.model';
import { MESSAGE_CENTER_URLS } from '../shared/MESSAGE_CENTER_URLS';
import { HttpService, RealtimeService } from '@app/core/services';
import { tap } from 'rxjs/operators';
import { WsEvent } from '@app/core/services/websocket/ws.models';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    private http: HttpService,
    private realtimeService: RealtimeService,
  ) { }

  getChatMessages(params?: object): Observable<PaginatedResponse<Message>> {
    return <Observable<PaginatedResponse<Message>>>this.http.GET(MESSAGE_CENTER_URLS.chat_message, params);
  }

  sendMessage(body: MessagePayload): Observable<Message> {
    return <Observable<Message>>this.http.POST(MESSAGE_CENTER_URLS.chat_message, body).pipe(
      tap((response: Message) => this.realtimeService.emitInternalEvent({
        event: WsEvent.newMessage,
        payload: response
      }))
    );
  }

  deleteMessage(messageId: number): Observable<void> {
    return <Observable<void>>this.http.DELETE(`${ MESSAGE_CENTER_URLS.chat_message }${ messageId }/`);
  }

  resendMessage(messageId: number): Observable<void> {
    return <Observable<void>>this.http.POST(`${ MESSAGE_CENTER_URLS.chat_message }${ messageId }/send_message/`, {});
  }
}
