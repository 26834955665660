import { PayerWidget, UserWidget } from '@app/models/widgets.model';
import { VisitStatus } from "@app/models/visits/visits.model";
import { VisitServiceType } from "@app/core/enums";

export interface VisitDetailsHeader {
  id: number;
  number: string;
  status: VisitStatus;
  status_value: string;
  patient: number;
  patient_detail: VisitDetailsPatientDetail;
  authorization: number;
  authorization_detail: VisitDetailsAuthDetail;
  payer: number;
  payer_detail: PayerWidget;
  service_name: string;
  is_nurse: boolean;
  service_type: VisitServiceType;
  service_type_value: string;
  is_confirmed: boolean;
  blasting_attentions_count: number;
  attentions: VisitAttentions[];
}

interface VisitDetailsAuthDetail {
  id: number;
  code: string;
}

interface VisitDetailsPatientDetail {
  id: number;
  full_name: string;
  phone_number: string;
  coordinators: UserWidget[];
  number: string;
}

export interface VisitDetailsUnitClaim {
  id: number;
  number: string;
  batch: UnitInvoiceBatchWidget;
}

export interface UnitInvoiceBatchWidget {
  id: number;
  number: string;
}

export enum VisitAttentions {
  TIME_OF_ASSIGNMENT_IS_ENDING,
  HAS_NOT_VIEWED_NEW_BLASTING_OFFERS,
  NOT_TRANSFER_TO_EN_ROUTE_BEFORE_START,
  WAS_CANCELED,
  NOT_TRANSFER_TO_IN_PROGRESS_AFTER_START,
  TIME_TO_CLOCK_IN_WAS_EXPIRED,
  TIME_TO_CLOCK_OUT_WAS_EXPIRED,
  POC_COMPLIANCE,
  OT_TT_NOT_APPROVED,
  IS_CONFIRMED,
  HAS_OVERTIME,
  HAS_OVERLAPPING,
  NOT_TRANSFER_TO_EN_ROUTE_BEFORE_START_5_MIN,
  NOT_TRANSFER_TO_EN_ROUTE_BEFORE_START_15_MIN,
  NOT_TRANSFER_TO_EN_ROUTE_BEFORE_START_30_MIN,
}
