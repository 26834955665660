import { inject } from '@angular/core';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { PayerBatchesService } from '../services';
import { Observable } from 'rxjs';
import { toPaginatedResponse } from '../rx-operators';
import { SelectPayerUnbatchVisit } from '@app/models/payer/payer-batches.model';
import { BatchStatus } from '../enums';
import { map } from 'rxjs/operators';
import { IdNameWidgetResponse } from '@app/models/response.model';

export class BatchesOptionsLoader implements OptionsLoader {
  private payerBatchesService = inject(PayerBatchesService);

  getOptions(params?: any): Observable<PaginatedResponse<IdNameWidgetResponse>> {
    return this.payerBatchesService.getSelectPayerUnbatchVisits({
      statuses: BatchStatus.NotBilled,
      widget: 'fk',
      no_limits: true,
      ...(params ?? {}),
    }).pipe(
      toPaginatedResponse(),
      map((response: PaginatedResponse<SelectPayerUnbatchVisit>) => {
        return {
          count: response.count,
          results: response.results.map(visit => ({
            id: visit.id,
            name: `#${ visit.number } (${ visit.start_date } - ${ visit.end_date })`,
          }))
        };
      })
    );
  }
}
