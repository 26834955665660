import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { toPaginatedResponse } from '@app/core/rx-operators';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { EdiPartnerParams, EdiPartner } from '@app/models/settings/billing/edi-provider.model';
import { BillingService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';

export class EdiPartnersOptionsLoader implements OptionsLoader {
  private billingService: BillingService = inject<BillingService>(BillingService);
  
  getOptions(params?: EdiPartnerParams): Observable<PaginatedResponse<EdiPartner>> {
    return this.billingService.getEdiPartners(params).pipe(
      map((partners: EdiPartner[]) => partners.map(p => ({ ...p, name: `${ p.name } (${ p.claim_type_value[0] })` }))),
      toPaginatedResponse()
    );
  }
}