import { Directive, inject, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationDialogComponent } from '@app/shared/popups/confirmation-dialog/confirmation-dialog.component';

@UntilDestroy()
@Directive()
export abstract class BaseEditableDialogComponent<T, C = any> {
  protected dialogRef: MatDialogRef<T> = inject<MatDialogRef<T>>(MatDialogRef<T>);
  protected dialog: MatDialog = inject(MatDialog);

  protected hasChanges(): boolean {
    return false;
  }

  protected close(data: C = null) {
    if (this.hasChanges()) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: {
            text: 'visitDetails.leaveFormConfirmation',
            buttonLabel: 'common.yes',
            buttonClass: 'app-button_blue',
          },
        });

        dialogRef.afterClosed()
          .pipe(untilDestroyed(this))
          .subscribe((status) => {
            if (status) this.dialogRef.close(data);
          });
      } else {
        this.dialogRef.close(data);
      }
  }

}
