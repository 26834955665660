import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService, RealtimeService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { EditPayerData } from '@app/models/payer/edit-payer-data.model';
import { GetPayersRequestParams, Payer } from '@app/models/payer/payer.model';
import { tap } from 'rxjs/operators';
import { WsEvent } from '@app/core/services/websocket/ws.models';

@Injectable({
  providedIn: 'root'
})
export class PayerService {
  constructor(
    private http: HttpService,
    private realtimeService: RealtimeService,
  ) { }

  getPayers(params: GetPayersRequestParams = null): Observable<PaginatedResponse<Payer> | Payer[]> {
    return this.http.GET<PaginatedResponse<Payer> | Payer[]>(URLS.payer, params);
  }

  getPayer(id: number): Observable<EditPayerData> {
    return this.http.GET<EditPayerData>(`${ URLS.payer }${ id }/`);
  }

  editPayer(payerId: number, payerData: Record<string, unknown>): Observable<EditPayerData> {
    return this.http.PUT<EditPayerData>(`${ URLS.payer }${ payerId }/`, payerData).pipe(
      tap((payer) => this.realtimeService.emitInternalEvent({ event: WsEvent.fe_internal_payer_update, payload: payer }))
    );
  }

  createPayer(payer): Observable<Payer> {
    return this.http.POST(URLS.payer, payer);
  }
}
