<div class="user-card">

  <div class="main">
    <app-avatar
      *ngIf="editorInfoCard.avatar !== null"
      [text]="editorInfoCard.name.charAt(0)"
      [imageUrl]="editorInfoCard.avatar"
      size="100"
      borderRadius="6">
    </app-avatar>

    <div class="user-info" [ngStyle]="{ 'justify-content': editorInfoCard.profileTypeValue || editorInfoCard.phone || editorInfoCard.email ? 'space-between' : 'center' }">
      <div class="user-info_name">{{ editorInfoCard.name }}</div>
      <div *ngIf="editorInfoCard.initials" class="user-info_name">{{ editorInfoCard.initials }}</div>
      <div class="user-info_position" *ngIf="editorInfoCard.profileTypeValue">
        {{ editorInfoCard.profileTypeValue }}
        <span *ngIf="editorInfoCard.employmentTypeCode">{{ editorInfoCard.employmentTypeCode }}</span>
      </div>

      <ul class="user-info_links" *ngIf="editorInfoCard.phone || editorInfoCard.email">
        <li *ngIf="editorInfoCard.phone" class="user-info_links-item">
          <a matRipple href="tel:{{ editorInfoCard.phone | imask:phoneMask  }}">
            <mat-icon svgIcon="phone"></mat-icon>
          </a>
        </li>

        <li *ngIf="showChatIcon"
            class="user-info_links-item">
          <svg-icon
            class="icon-contact"
            src="/assets/icons/chat.svg"
            (click)="openChat()">
          </svg-icon>
        </li>

        <li *ngIf="editorInfoCard.email" class="user-info_links-item">
          <a matRipple href="mailto:{{ editorInfoCard.email }}">
            <mat-icon svgIcon="email"></mat-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <ul *ngIf="editorInfoCard.phone || editorInfoCard.email || editorInfoCard.status != null" class="contacts">
    <li *ngIf="editorInfoCard.userNumber" class="contacts-item">
      <span class="contacts-item_name">{{ idTitle | translate }}:</span>
      <span class="contacts-item_value ellipsis">{{ editorInfoCard.userNumber }}</span>
    </li>

    <li *ngIf="editorInfoCard.profileType === UserType.Caregiver && editorInfoCard.pinCode != null" class="contacts-item">
      <span class="contacts-item_name">{{ "userDetails.editMenu_field_pinCode" | translate }}:</span>
      <span class="contacts-item_value ellipsis pin-code">
        {{ editorInfoCard.pinCode }}
        <span
          class="material-icons-two-tone icon-blue refresh-icon"
          matTooltip="{{ 'userDetails.editMenu_tooltip_pinCode' | translate }}"
          [class.generating]="isGenerating"
          (click)="refreshPinCode()">
          refresh
        </span>
      </span>
    </li>

    <li *ngIf="editorInfoCard.phone" class="contacts-item">
      <span class="contacts-item_name">{{ "userDetails.editMenu_field_phone" | translate }}:</span>
      <span class="contacts-item_value ellipsis">{{ editorInfoCard.phone | imask:phoneMask }}</span>
    </li>

    <li *ngIf="editorInfoCard.fax" class="contacts-item">
      <span class="contacts-item_name">{{ 'userDetails.editMenu_field_fax' | translate }}:</span>
      <span class="contacts-item_value ellipsis">{{ editorInfoCard.fax | imask:phoneMask }}</span>
    </li>

    <li *ngIf="editorInfoCard.email" class="contacts-item" [matTooltip]="editorInfoCard.email.length > 20 ? editorInfoCard.email : ''">
      <span class="contacts-item_name">{{ 'userDetails.editMenu_field_email' | translate }}:</span>
      <span class="contacts-item_value ellipsis">{{ editorInfoCard.email | truncate:20:true }}</span>
    </li>


    <li *ngIf="editorInfoCard.patientPriority != null" class="contacts-item">
      <span class="contacts-item_name"
            [matTooltip]="'createUser.form_field_treatmentAssistanceLevel' | translate"
      >{{ 'userDetails.editMenu_field_patientPriority' | translate }}:</span>

      <app-status-pill
        class="contacts-item_status"
        [label]="PatientPriorityTranslations[editorInfoCard.patientPriority]"
        [status]="editorInfoCard.patientPriority"
        [Enum]="PatientPriorityLevel">
      </app-status-pill>
    </li>

    <li *ngIf="editorInfoCard.status_value" class="contacts-item">
      <span class="contacts-item_name">{{ 'userDetails.editMenu_field_status' | translate }}:</span>
      <app-status-pill
        class="contacts-item_status"
        [label]="editorInfoCard.status_value"
        [status]="editorInfoCard.status"
        [Enum]="Enum">
      </app-status-pill>
    </li>

    <li *ngIf="editorInfoCard.profileType === UserType.Caregiver" class="contacts-item">
      <span class="contacts-item_name">{{ 'userDetails.editMenu_field_receiveBlasting' | translate }}:</span>
      <span class="contacts-item_value receive-blasting">
        <mat-slide-toggle
          [checked]="editorInfoCard.enable_blasting"
          [disabled]="isSwitchingBlasting"
          (change)="switchReceiveBlasting($event.checked)">
        </mat-slide-toggle>
      </span>
    </li>
  </ul>
</div>
