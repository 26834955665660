import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VisitDetailsComponent } from '@app/pages/visit-details/visit-details.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IndexedVisitWindow, VisitWindow, VisitWindowsInfo } from '@app/pages/visit-floating-windows/models';
import { map } from 'rxjs/operators';

const HIDDEN_WINDOW_CLASS = 'visit-window-collapsed';
const BACKDROP_CLASS = 'visit-dialog-backdrop';
const HIDDEN_BACKDROP_CLASS = BACKDROP_CLASS + '_hidden';
const MAX_FLOATING_WINDOWS = 3;

@Injectable()
export class VisitWindowsService {
  windowsInfo$: Observable<VisitWindowsInfo>;

  private windowsSubject = new BehaviorSubject<IndexedVisitWindow[]>([]);
  private openedDialogRef: MatDialogRef<VisitDetailsComponent>;
  private lastSelectedIndex = -1;

  get windows(): IndexedVisitWindow[] {
    return this.windowsSubject.value;
  }

  constructor(private dialog: MatDialog) {
    this.windowsInfo$ = this.windowsSubject.asObservable().pipe(map(windows => {
        return { windows, reachedLimit: windows.length >= MAX_FLOATING_WINDOWS };
    }));
  }

  collapseWindow(window: VisitWindow): void {
    this.windowsSubject.next([...this.windows, { ...window, index: this.getFreeWindowIndex() }]);
    window.component.dialogRef.addPanelClass(HIDDEN_WINDOW_CLASS);
    window.component.dialogRef.overlayRef.backdropElement.classList.add(HIDDEN_BACKDROP_CLASS);
    this.openedDialogRef = null;
  }

  expandWindow(visitId: number): void {
    const window = this.windows.find(item => item.visitId === visitId);

    if (window) {
      this.removeWindow(window.visitId);
      window.component.dialogRef.removePanelClass(HIDDEN_WINDOW_CLASS);
      window.component.dialogRef.overlayRef.backdropElement.classList.remove(HIDDEN_BACKDROP_CLASS);
      this.openedDialogRef = window.component.dialogRef as any;
      this.lastSelectedIndex = window.index;
    } else {
      console.error('Not Found window with visit ID - ', visitId);
    }
  }

  openWindow(visitId: number): void {
    const index = this.windows.findIndex(item => item.visitId === visitId);

    if (index === -1) {
      this.createDialog(visitId);
    } else {
      this.expandWindow(visitId);
    }
  }

  closeWindow(window: VisitWindow): void {
    window.component.close();
  }

  toNextWindow(window: VisitWindow): void {
    const windowToOpen = this.findNextWindow(this.lastSelectedIndex) || this.findNextWindow(-1);

    this.expandWindow(windowToOpen.visitId);
    this.collapseWindow(window);
  }

  toPrevWindow(window: VisitWindow): void {
    const windowToOpen = this.findPrevWindow(this.lastSelectedIndex) || this.findPrevWindow(MAX_FLOATING_WINDOWS);

    this.expandWindow(windowToOpen.visitId);
    this.collapseWindow(window);
  }

  private removeWindow(visitId: number): void {
    this.windowsSubject.next(this.windowsSubject.value.filter(item => item.visitId !== visitId));
  }

  private createDialog(visitId: number): void {
    if (this.openedDialogRef) {
      return;
    }

    this.openedDialogRef = this.dialog.open(VisitDetailsComponent, {
      width: '740px',
      autoFocus: false,
      panelClass: 'visit-dialog-panel',
      backdropClass: BACKDROP_CLASS,
      data: { visitId },
    });

    this.openedDialogRef.afterClosed().subscribe(() => {
      this.openedDialogRef = null;
      this.removeWindow(visitId);
    });
  }

  private getFreeWindowIndex(): number {
    for (let i = 0; i < MAX_FLOATING_WINDOWS; i++) {
      if (this.windowsSubject.value.every(chat => chat.index !== i)) {
        return i;
      }
    }
  }

  private findNextWindow(index: number): IndexedVisitWindow {
    const largerNumbers = this.windows.filter(item => item.index > index);
    if (largerNumbers.length === 0) {
      return null;
    }

    return largerNumbers.reduce((closest, current) =>
      Math.abs(current.index - index) < Math.abs(current.index - index) ? current : closest
    );
  }

  private findPrevWindow(index: number): IndexedVisitWindow {
    const lowerNumbers = this.windows.filter(item => item.index < index);
    if (lowerNumbers.length === 0) {
      return null;
    }

    return lowerNumbers.reduce((closest, current) =>
      Math.abs(current.index - index) < Math.abs(closest.index - index) ? current : closest
    );
  }
}
