export enum PaymentReminder {
  OneDay,
  TwoDays,
  ThreeDays,
  FiveDays,
  OneWeek,
  TenDays,
  TwoWeeks,
  OneMonth,
  FourtyFiveDays
}