import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { OptionChangeEvent } from '@app/shared/fields/autocomplete-field/models';
import { BaseSelectComponent } from '@app/shared/fields/base-select/select.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { MergeStrategy } from '@app/core/components';

@Component({
  selector: 'app-autocomplete-field',
  templateUrl: 'autocomplete-field.component.html',
  styleUrls: ['autocomplete-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteFieldComponent),
      multi: true
    }
  ]
})
export class AutocompleteFieldComponent extends BaseSelectComponent implements ControlValueAccessor, OnInit {
  @Input() minSearchSymbols = 0;
  @Input() placeholder = 'placeholders.select';
  @Input() translateLabel = false;
  @Input() clearable = true;
  @Input() searchable = true;
  @Input() tabIndex: number = 1;

  @Output() optionSelect: EventEmitter<OptionChangeEvent> = new EventEmitter<OptionChangeEvent>();

  @ViewChild('inputElement') input: ElementRef<HTMLInputElement>;

  selectedOption: any;
  isPanelOpened = false;

  private searchSubject: Subject<string> = new Subject<string>();
  private isSearchInputWasChangedByUser = false;

  get isFilledRequiredSymbolsLength(): boolean {
    return this.searchText?.length >= this.minSearchSymbols;
  }

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.searchSubject.pipe(
      tap(() => this.loading = true),
      debounceTime(this.SEARCH_DEBOUNCE_TIME),
      untilDestroyed(this)
    ).subscribe(query => {
      if (!this.isPanelOpened) {
        this.loading = false;
        return;
      }

      this.searchText = query ?? '';
      this.mergeStrategy = MergeStrategy.Replace;
      this.pagination.offsetChanged(0);
      this.loadOptions();
    });
  }

  onSearch(query: string): void {
    this.searchText = query ?? '';
    this.isSearchInputWasChangedByUser = true;

    if (this.loadStrategy === 'none' || query.length < this.minSearchSymbols) {
      return;
    }

    this.searchSubject.next(query);
  }

  reset(): void {
    this.selectOption(null);
    this.onSearch('');
    this.onBlur();
  }

  selectOption(value: string | number | null) {
    if (value === this.value) {
      return;
    }

    this.value = value ?? null;
    this.selectedOption = this.findOption(this.value);
    this.searchText = this.selectedOption ? this.translate(this.selectedOption[this.bindLabel]) : '';

    this.onChangeCallback(this.value);
    this.optionSelect.emit({ value, label: this.searchText, optionData: this.selectedOption });
  }

  findOption(value: string | number): any {
    return this.options.find(option => option[this.bindValue] === value);
  }

  findOptionLabel(value: string | number): string {
    const option = this.findOption(value);

    return option ? this.translate(option[this.bindLabel]) : '';
  }

  onFocus(): void {
    if (!this.isFilledRequiredSymbolsLength) {
      return;
    }

    super.onFocus();
  }

  onInputClick(trigger: MatAutocompleteTrigger): void {
    if (!this.searchable) {
      trigger.panelOpen ? trigger.closePanel() : trigger.openPanel();
    }
  }

  onPanelOpen(): void {
    this.isPanelOpened = true;
  }

  onPanelClose(): void {
    this.isPanelOpened = false;
    if (this.selectedOption) {
      this.searchText = this.translate(this.selectedOption[this.bindLabel]);
    } else {
      this.searchText = '';
    }
  }

  protected override updateInputView(): void {
    // Update input text only if options were load silently without changing input manually by user
    if (!this.optionsWereLoadedAtLeastOnce && !this.isSearchInputWasChangedByUser) {
      this.selectedOption = this.findOption(this.value);
      this.searchText = this.selectedOption ? this.translate(this.selectedOption[this.bindLabel]) : '';
      if (this.input) {
        this.input.nativeElement.value = this.searchText;
      }
    }
  }

  private translate(label: string): string {
    return this.translateLabel ? this.translateService.instant(label) : (label ?? '');
  }
}
