import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { DictionariesService } from '@app/core/services/dictionaries.service';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { toPaginatedResponse } from '@app/core/rx-operators';

export class DictionariesOptionsLoader implements OptionsLoader {
  private dictionariesService = inject(DictionariesService);

  getOptions(params?: object): Observable<PaginatedResponse<any>> {
    return this.dictionariesService.getDictionary({
      dictionary: 'country',
      widget: 'fk',
      paginate: true,
      limit: 15,
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
