import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges, SimpleChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { Message } from '@app/pages/message-center/models/message.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { ChatService, MessageCenterAuthService } from '@app/pages/message-center/services';
import { WsConnectionModule, WsEvent, WsAction } from '@app/core/services/websocket/ws.models';
import { ModuleSubscription } from '@app/core/services';
import { getWsData } from '@app/core/services/websocket/rxjs-ws-operators';
import { ItemsComponent, MergeStrategy } from '@app/core/components';
import { getRemainingScrollDistanceToBottom } from '@app/shared/helper';
import { PaginationIfc } from '@app/shared/interfaces/pagination.class';
import { MCProfile } from '@app/pages/message-center/models/user.model';
import { ConversationType } from '@app/pages/message-center/models/chat.model';

@UntilDestroy()
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesComponent extends ItemsComponent<Message> implements OnInit, OnChanges, OnDestroy {
  @Input() chatId: number;
  @Input() chatMembers: any[];
  @Input() chatType: ConversationType;
  @Input() threadMessage?: Message;
  @Input() readNewMessages = true;
  @Output() threadMessageSelect = new EventEmitter<Message>();

  readonly ConversationType = ConversationType;

  public openedWsModule: ModuleSubscription;
  public override pagination: PaginationIfc = new PaginationIfc(20);
  public profile: MCProfile = this.messageCenterAuthService.getProfile();

  protected override responseMergeStrategy = MergeStrategy.Append;

  @ViewChild(CdkScrollable) private scrollContainer: CdkScrollable;

  get isThread(): boolean {
    return !!this.threadMessage;
  }

  constructor(private chatService: ChatService,
              private messageCenterAuthService: MessageCenterAuthService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToWebsockets();
  }

  ngOnChanges(changes: SimpleChanges) {
    const threadMessage = changes.threadMessage;
    const isThreadMessageChanged = !threadMessage?.firstChange && threadMessage?.previousValue?.id !== threadMessage?.currentValue?.id;

    if (isThreadMessageChanged) {
      this.items = [];
      this.pagination.offsetChanged(0);
      this.loadItems();
    }
  }

  onScroll(): void {
    const scroll = getRemainingScrollDistanceToBottom(this.scrollContainer.getElementRef().nativeElement);
    if (scroll < 150 && !this.isLoadedAllItems && !this.isLoading) {
      this.loadItems();
    }
  }

  protected getItems(): Observable<PaginatedResponse<Message>> {
    const params: any = {
      offset: this.items.length,
      limit: this.pagination.limit,
      chat_id: this.chatId
    };

    if (this.isThread) {
      params.reply_to_id = this.threadMessage.id;
    }

    return this.chatService.getChatMessages(params);
  }

  private subscribeToWebsockets(): void {
    this.openedWsModule = this.realtimeService.subscribeToModule({
      user_id: this.profile.id,
      module: WsConnectionModule.chat,
      module_pk: this.chatId
    });

    this.realtimeService.messages$
      .pipe(getWsData(), untilDestroyed(this))
      .subscribe((data) => {
        const payload = data.payload;

        switch (data.event) {
          case WsEvent.newMessage:
            this.handleNewMessage(payload);
            break;
          case WsEvent.messageRepliesInfoUpdating:
            this.updateItem({ id: payload.id, replies_info: payload });
            break;
          case WsEvent.userViewedMessage:
            this.updateItem({ id: payload.id, is_viewed: true });
            break;
          case WsEvent.messageStatusUpdating:
            this.updateItem(payload);
            break;
          case WsEvent.deleteMessage:
            this.deleteItem(payload);
            break;
        }
      });
  }

  private handleNewMessage(message: Message): void {
    if (message.chat !== this.chatId) {
      return;
    }

    const isMessageExist = this.items.some(item => item.id === message.id);

    if (!isMessageExist && (!this.isThread && !message.reply_to) || (this.isThread && this.threadMessage.id === message.reply_to)) {
      this.createItem(message);

      if (this.readNewMessages) {
        this.readMessage(message);
      }
    }
  }

  readMessage(message: Message): void {
    if (message.sender.id === this.profile.id) {
      return;
    }

    this.openedWsModule.websocket.sendMessage({
      action: WsAction.userViewedMessage, payload: {
        company_id: this.profile.company.id,
        user_id: this.profile.id,
        message_id: message.id,
        chat_id: message.chat
      }
    });

    this.realtimeService.emitInternalEvent({ event: WsEvent.userViewedMessage, payload: message });
  }

  ngOnDestroy() {
    this.openedWsModule?.unsubscribe();
  }
}
