<input class="input"
       type="text"
       #inputElement
       #trigger="matAutocompleteTrigger"
       tabindex="0"
       [class.searchable]="searchable"
       [value]="searchText"
       [placeholder]="placeholder | translate"
       [matAutocomplete]="auto"
       [class.invalid]="invalid"
       [disabled]="disabled"
       [readonly]="!searchable"
       (click)="onInputClick(trigger)"
       (blur)="onBlur()"
       (input)="onSearch($event.target.value)"
       (focus)="onFocus()">

<svg-icon *ngIf="clearable && !disabled && value != null" class="reset" src="/assets/icons/close_24dp.svg" (click)="reset()"></svg-icon>
<div class="caret-icon" [class.opened]="auto.isOpen"></div>

<mat-autocomplete #auto="matAutocomplete"
                  class="app-autocomplete-field-panel"
                  matOptionsScroll
                  [displayWith]="findOptionLabel.bind(this)"
                  (opened)="onPanelOpen()"
                  (closed)="onPanelClose()"
                  (optionsScroll)="onScroll($event)"
                  (optionSelected)="selectOption($event.option.value)">

  <ng-container *ngIf="isFilledRequiredSymbolsLength; else symbolsCountTip">
    <mat-option *ngIf="options.length === 0 && !loading" class="option option_no-data" disabled>
      {{ 'commonNotifications.noResultsFound' | translate }}
    </mat-option>

    <ng-container *ngIf="!loading || mergeStrategy !== MergeStrategy.Replace">
      <mat-option *ngFor="let option of options; trackBy: trackByOption"
                  class="option"
                  [class.option-selected]="option[bindValue] === value"
                  [value]="option[bindValue]">
        {{ translateLabel ? (option[this.bindLabel] | translate) : option[this.bindLabel] }}
      </mat-option>
    </ng-container>

    <mat-option *ngIf="loading" class="option option_loading" disabled>
      <app-loading size="small"></app-loading>
    </mat-option>
  </ng-container>

  <ng-template #symbolsCountTip>
    <mat-option class="option option_symbols-hint" disabled>
      {{ 'autocomplete.typeMoreSymbols' | translate: { count:  minSearchSymbols - searchText?.length } }}
    </mat-option>
  </ng-template>

</mat-autocomplete>
