import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { GetPhysiciansRequestParams, Physician } from '@app/models/physician/physician.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { BehaviorSubject } from 'rxjs';
import { EditPhysicianData } from '@app/models/physician/edit-physician-data';

@Injectable({
  providedIn: 'root'
})
export class PhysicianService {

  physician$: BehaviorSubject<EditPhysicianData> = new BehaviorSubject<EditPhysicianData>(null);

  constructor(
    private http: HttpService
  ) { }

  getPhysicians<T = PaginatedResponse<Physician>>(params: GetPhysiciansRequestParams): Observable<T> {
    return this.http.GET<T>(URLS.physician, params);
  }

  getPhysicianById(physicianId: number): Observable<Physician> {
    return this.http.GET<Physician>(`${ URLS.physician }${ physicianId }/`);
  }

  updatePhysician(physicianId: number, data: Physician): Observable<Physician> {
    return this.http.PUT<Physician>(`${ URLS.physician }${ physicianId }/`, data);
  }

  createPhysician(physician): Observable<Physician> {
    return this.http.POST(URLS.physician, physician);
  }
}
