<mat-select class="multiselect-field"
            panelClass="app-multiselect-field-panel {{ size }}"
            multiple
            matOptionsScroll
            #matSelect="matSelect"
            [class.fake-value]="showValueAsAll"
            [disableOptionCentering]="true"
            [placeholder]="showValueAsAll ? (inputPrefix + (allOptionLabel | translate)) : (placeholder | translate)"
            [disabled]="disabled"
            [class.invalid]="invalid"
            [(ngModel)]="value"
            (focus)="onFocus()"
            (closed)="onBlur()"
            (opened)="onPanelOpen()"
            (optionsScroll)="onScroll($event)"
            (selectionChange)="onOptionSelected()">
  <mat-select-trigger>
    <span class="selected-text">
      {{ inputPrefix }}

      <ng-container *ngIf="!showValueAsAll else allText">
        <ng-container *ngIf="!showValueAsCounter">
           {{ selectedLabels | arrayJoin: { delimiter: ' / ' } }}
        </ng-container>

        <ng-container *ngIf="showValueAsCounter">
          {{ value.length }}<span *ngIf="totalItemsWithoutFiltering && loadStrategy !== 'searchOnly'">/{{ totalItemsWithoutFiltering }}</span>
        </ng-container>
      </ng-container>
    </span>
  </mat-select-trigger>

  <div class="search-container" *ngIf="searchable">
    <input matInput
           [formControl]="searchControl"
           [placeholder]="'placeholders.typeToSearch' | translate"/>

    <mat-icon *ngIf="isFilterApplied" class="clear-icon" (click)="searchControl.setValue(null)">close</mat-icon>
  </div>

  <mat-option *ngIf="showToggleAllOption && !isFilterApplied" class="option option_all" disabled (click)="toggleAllOptions()">
    <mat-checkbox class="checkbox_toggle-all" [checked]="isAllOptionsSelected" disabled></mat-checkbox>

    <ng-container [ngTemplateOutlet]="allText"></ng-container>
  </mat-option>

  <mat-option *ngFor="let option of options"
              class="option"
              [hidden]="loading && mergeStrategy === MergeStrategy.Replace"
              [value]="option[bindValue]">
    {{ translateLabel ? (option[bindLabel] | translate) : option[bindLabel]  }}
  </mat-option>

  <app-loading *ngIf="loading" size="small"></app-loading>

  <mat-option *ngIf="!loading && !options?.length"
              class="option option_no-data"
              disabled
              [hidden]="loadStrategy === 'searchOnly' && !isFilterApplied">
    {{ 'commonNotifications.noResultsFound' | translate }}
  </mat-option>

  <!-- Always keep selected options in DOM in case of filtering.
   Because when no options that has selected value then mat-select-trigger is not rendering -->
  <mat-option *ngFor="let item of value" [hidden]="true" [value]="item"></mat-option>
</mat-select>

<ng-template #allText>{{ allOptionLabel | translate }}</ng-template>

<mat-icon *ngIf="clearable && !disabled && value?.length" matSuffix class="reset" (click)="reset()">close</mat-icon>
