export enum BatchStatus {
    New,
    Ready,
    Submitted,
    Accepted,
    Rejected,
    Approved,
    Denied,
    Pended,
    Voided,
    Paid,
    PartialPaid,
    NotBilled,
    Billed,
    Open,
    Closed,
    Incomplete,
    Completed,
    NotPaid,
    ReadyToRun
  }