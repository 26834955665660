<div
  *ngIf="addressDetails && details"
  #map
  class="map-base">
  <google-map
    width="100%"
    height="100%"
    [center]="addressDetails?.address_point"
    [zoom]="initialZoom"
    [options]="mapOptions">

    <map-circle
      *ngIf="!!addressDetails?.address_point && details.start_distance_status === EVVStatus.Excellent"
      [center]="addressDetails?.address_point"
      [radius]="circleRadiusExcellent"
      [options]="circleOptionsExcellent">
    </map-circle>

    <map-circle
      *ngIf="!!addressDetails?.address_point && details.start_distance_status === EVVStatus.Acceptable"
      [center]="addressDetails?.address_point"
      [radius]="circleRadiusAcceptable"
      [options]="circleOptionsAcceptable">
    </map-circle>

    <map-circle
      *ngIf="!!addressDetails?.address_point && details.start_distance_status === EVVStatus.NotAcceptable"
      [center]="addressDetails?.address_point"
      [radius]="circleRadiusNotAcceptable"
      [options]="circleOptionsNotAcceptable">
    </map-circle>

    <map-marker
      #homeMarker="mapMarker"
      [visible]="!!addressDetails?.address_point"
      [icon]="homeIcon"
      [position]="addressDetails?.address_point">
    </map-marker>


    <map-marker
      #clockInMarker="mapMarker"
      [visible]="!!details?.start_location_point"
      [icon]="clockInIcon"
      [position]="details?.start_location_point"
      (mapClick)="openLocationStartInfoWindow(clockInMarker)">
    </map-marker>

    <map-marker
      #clockOutMarker="mapMarker"
      [visible]="!!details?.end_location_point"
      [icon]="clockOutIcon"
      [position]="details?.end_location_point"
      (mapClick)="openLocationEndInfoWindow(clockOutMarker)"></map-marker>

    <map-info-window class="location-info-window" #locationStartInfoWindow="mapInfoWindow">
      <div class="info-window">
        {{ 'visitDetails.tabVisit_map_gps' | translate }}: {{ details?.start_location_point?.lat | number:'1.0-6' }} {{ details?.start_location_point?.lng | number:'1.0-6' }}
      </div>
    </map-info-window>

    <map-info-window class="location-info-window" #locationEndInfoWindow="mapInfoWindow">
      <div class="info-window">
        {{ 'visitDetails.tabVisit_map_gps' | translate }}: {{ details?.end_location_point?.lat | number:'1.0-6' }} {{ details?.end_location_point?.lng | number:'1.0-6' }}
      </div>
    </map-info-window>
  </google-map>
</div>
