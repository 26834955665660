import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimePeriod, TimepickerTime } from '@app/shared/components/timepicker-field/time.models';

const PERIOD_OPTIONS = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' }
];

@Component({
  selector: 'app-timepicker-field',
  templateUrl: './timepicker-field.component.html',
  styleUrls: ['./timepicker-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerFieldComponent),
      multi: true
    }
  ]
})
export class TimepickerFieldComponent implements ControlValueAccessor {
  @Input() stepMinutes: number = 1;
  @Output() timeChanged = new EventEmitter<string>();
  @Output() periodChanged = new EventEmitter<TimePeriod>();

  readonly periodOptions = PERIOD_OPTIONS;

  hour: number;
  minute: number;
  period: TimePeriod = 'AM';
  isPeriodSelectionOpened: boolean;

  onTouched: () => void;
  private onChange: (value: string) => void;

  constructor(private cdr: ChangeDetectorRef) {
  }

  writeValue(value: string): void {
    if (value) {
      const timeParts = value.split(':');
      this.hour = parseInt(timeParts[0], 10);
      this.minute = +timeParts[1].slice(0, 2);
      this.period = timeParts[1].slice(3) as TimePeriod;
    } else {
      this.hour = null;
      this.minute = null;
      this.period = 'AM';
    }
  }

  onTimeChanged(): void {
    if (this.hour !== null && this.minute === null) {
      this.minute = 0;
    }

    if (this.hour === null && this.minute !== null) {
      this.hour = 0;
    }

    const time = `${ this.hour }:${ this.minute } ${ this.period }`;
    this.onChange(time);
    this.timeChanged.emit(time);
  }

  changeTimePeriod(period: TimePeriod): void {
    this.period = period;
    this.onTimeChanged();
    this.periodChanged.emit(period);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onPeriodPanelToggle(isOpen: boolean): void {
    this.isPeriodSelectionOpened = isOpen;
    if (!isOpen) {
      this.onTouched();
    }
  }
}

export function parseTimepickerValue(time: string): TimepickerTime {
  const [hoursStr, minutesStr, period] = time.split(/:| /);

  return {
    hours: parseInt(hoursStr, 10),
    minutes: parseInt(minutesStr, 10),
    period: period as TimePeriod,
  };
}

export function getHourIn24Format(hours: number, median: TimePeriod): number {
  if (hours === 12) {
    hours = 0;
  }

  return median === 'AM' ? hours : hours + 12;
}
