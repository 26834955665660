import { Directive, ElementRef, OnDestroy } from '@angular/core';
import {
  animate,
  style,
  AnimationBuilder,
  AnimationPlayer,
  AnimationAnimateMetadata,
  AnimationStyleMetadata,
  AnimationMetadata
} from '@angular/animations';

@Directive({
  selector: '[panelContent]',
})
export class PanelContentDirective implements OnDestroy {
  private player: AnimationPlayer;

  constructor(private element: ElementRef,
              private animationBuilder: AnimationBuilder) {
  }

  _init(isExpanded: boolean, hiddenContentHeight: string): void {
    if (!isExpanded) {
      this.showAnimation(this.hideAnimationMetadata(0, hiddenContentHeight));
    }
  }

  onToggle(expanded: boolean, height: string): void {
    const metadata = expanded ? this.showAnimationMetadata(200, height) : this.hideAnimationMetadata(200, height);
    this.showAnimation(metadata);
  }

  private showAnimation(animationMetadata: AnimationMetadata | AnimationMetadata[]): void {
    if (this.player) {
      this.player.destroy();
    }

    const factory = this.animationBuilder.build(animationMetadata);
    this.player = factory.create(this.element.nativeElement);

    this.player.play();
  }

  private hideAnimationMetadata(duration: number = 200, height: string): (AnimationAnimateMetadata | AnimationStyleMetadata)[] {
    const currentHeight = this.element.nativeElement.offsetHeight; // for smooth animation if inner elements were destroyed
    return [
      style({ height: currentHeight, visibility: 'visible', overflow: 'hidden' }),
      animate(`${ duration }ms ease-in`, style({ height, visibility: height === '0px' ? 'hidden' : 'visible' })),
    ];
  }

  private showAnimationMetadata(duration: number = 200, height: string): (AnimationAnimateMetadata | AnimationStyleMetadata)[] {
    return [
      style({ height: height, visibility: 'hidden', overflow: 'hidden' }),
      animate(`${ duration }ms ease-in`, style({ height: '*', visibility: 'visible' })),
      style({ height: 'auto', overflow: 'unset' }),
    ];
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.destroy();
    }
  }
}
