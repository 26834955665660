import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormControlNameExport } from '@app/shared/directives/export-control.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { IMaskModule } from 'angular-imask';
import { AngularSvgIconModule } from 'angular-svg-icon';

// Modules
import { ElementsModule } from './elements';
import { FieldsModule } from './fields';
import { ChipModule } from './chip';
import { MaterialModule } from '@app/material.module';
import { PipesModule } from './pipes/pipes.module';

// Directives
import { MapResizableDirective } from '@app/shared/directives/map-resize.directive';
import { FocusDirective } from '@app/shared/directives/focus.directive';
import { ShowIfTruncatedDirective } from '@app/shared/directives/show-if-truncated.directive';

// Components
import { TopPanelComponent } from '@app/shared/top-panel/top-panel.component';
import { EditorPanelInfoCardComponent } from './editor-panel-routing/editor-info-card/editor-info-card.component';
import { EditorPanelRoutingComponent } from './editor-panel-routing/editor-panel-routing.component';
import { EditorPanelMenuComponent } from './editor-panel-routing/editor-menu/editor-menu.component';
import { EditorInfoCardComponent } from './editor-panel/editor-info-card/editor-info-card.component';
import { EditorPanelComponent } from './editor-panel/editor-panel.component';
import { EditorMenuComponent } from './editor-panel/editor-menu/editor-menu.component';
import { PageSubmenuComponent } from './components/page-submenu/page-submenu.component';
import { SelectChipInputComponent } from './components/select-chip-input/select-chip-input.component';
import { GenerateLinkPopupComponent } from './popups/generate-link-popup/generate-link-popup.component';
import { VisitNotificationsComponent } from './components/visit-notifications/visit-notifications.component';
import { ScheduledInterviewsComponent } from './popups/scheduled-interviews/scheduled-interviews.component';
import { CaregiverCellComponent } from './components/caregiver-cell/caregiver-cell.component';
import { ConfirmationDialogComponent } from './popups/confirmation-dialog/confirmation-dialog.component';
import { SortTableComponent } from './components/sort-table/sort-table.component';
import { BillingButtonLinksComponent } from './components/billing-button-links/billing-button-links.component';
import { AvailabilityTableComponent } from './components/availability-table/availability-table.component';
import { AmpmFieldComponent } from './components/ampm-field/ampm-field.component';
import { TimepickerFieldComponent } from '@app/shared/components/timepicker-field/timepicker-field.component';
import {
  OverlayToggleButtonComponent
} from '@app/shared/components/overlay-toggle-button/overlay-toggle-button.component';
import { UploadButtonComponent } from '@app/shared/components/upload-button/upload-button.component';
import { AvatarModule } from '@app/shared/avatar/avatar.module';
import {
  DragAndDropFileUploadingModule
} from '@app/shared/components/drag-and-drop-file-uploading/drag-and-drop-file-uploading.module';
import { TrimInputModule } from '@app/shared/directives/trim-input/trim-input.module';
import { LoadingModule } from '@app/shared/components/loading/loading.module';
import { StatusPillModule } from './components/status-pill/status-pill.module';
import { NumberFieldModule } from '@app/shared/fields/number-field/number-field.module';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  FieldsModule,
  ElementsModule,
  IMaskModule,
  PipesModule,
  ChipModule,
  TranslateModule,
  NgSelectModule,
  ScrollingModule,
  RouterModule,
  AngularSvgIconModule,
  AvatarModule,
  DragAndDropFileUploadingModule,
  TrimInputModule,
  LoadingModule,
  FormControlNameExport,
  StatusPillModule,
  NumberFieldModule,
];

const directives = [
  MapResizableDirective,
  FocusDirective,
  ShowIfTruncatedDirective,
];

const components = [
  TopPanelComponent,
  EditorPanelRoutingComponent,
  EditorPanelMenuComponent,
  EditorPanelInfoCardComponent,
  EditorPanelComponent,
  EditorInfoCardComponent,
  EditorMenuComponent,
  ConfirmationDialogComponent,
  PageSubmenuComponent,
  SelectChipInputComponent,
  GenerateLinkPopupComponent,
  ScheduledInterviewsComponent,
  CaregiverCellComponent,
  SortTableComponent,
  BillingButtonLinksComponent,
  AvailabilityTableComponent,
  AmpmFieldComponent,
  TimepickerFieldComponent,
  VisitNotificationsComponent,
  OverlayToggleButtonComponent,
  UploadButtonComponent,
];

@NgModule({
  imports: [
    ...modules,
  ],
  declarations: [
    ...components,
    ...directives,

  ],
  exports: [
    ...modules,
    ...components,
    ...directives,
  ]
})
export class SharedModule {
}
