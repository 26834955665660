export const DEFAULT_MAP_STYLES: google.maps.MapTypeStyle[] = [
  { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] },
  { featureType: 'transit', elementType: 'labels', stylers: [{ visibility: 'off' }] },
  { featureType: 'road', elementType: 'labels', stylers: [{ visibility: 'off' }] },
  { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#a7bdd9' }] },
  { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#889bae' }] },
  { featureType: 'road.arterial', elementType: 'geometry.fill', stylers: [{ color: '#a1adbf' }] },
  { featureType: 'road.arterial', elementType: 'geometry.stroke', stylers: [{ color: '#818b98' }] },
  { featureType: 'road.local', elementType: 'geometry.fill', stylers: [{ color: '#D8E0E7' }] },
  { featureType: 'road.local', elementType: 'geometry.stroke', stylers: [{ color: '#bdc5c9' }] },
  { featureType: 'water', stylers: [{ color: '#90DAEE' }] }
]
