import { Observable, of } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { TranslateService } from '@ngx-translate/core';
import { FilterRequstParams, SearchRequestParams } from '@app/models/request.model';
import { RootInjector } from '@app/root-injector';

export interface StaticOptionsParams extends SearchRequestParams, FilterRequstParams {}

export class StaticOptionsLoader<T = object> implements OptionsLoader<T> {
  constructor(public options: T[]) {
  }

  getOptions(params?: StaticOptionsParams): Observable<PaginatedResponse<T>> {
    const translate: TranslateService = RootInjector.injector.get(TranslateService);
    if (params?.search) {
      if (!params.lookup_field) {
        throw new Error('You must specify a lookup_field');
      }

      const filteredOptions = this.options
        .filter(option => translate.instant(String(option[params.lookup_field]).toLowerCase())
          .includes(params.search.toLowerCase()));

      return of({
        results: filteredOptions,
        count: filteredOptions.length,
      });
    }

    // Added possibility to filter by some property (id, value, etc)
    // TODO: concat search filter and this one
    if (params?.filterField && params?.filterValues) {
      return of({
        results: this.options.filter(option => params.filterValues.includes(option[params.filterField])),
        count: this.options.length
      });
    }

    return of({
      results: [...this.options],
      count: this.options.length
    });
  }
}
