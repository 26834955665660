import { DEFAULT_MAP_STYLES } from '@app/shared/map/styles/map-styles';

export const circleOptionsExcellent: google.maps.CircleOptions = {
    fillColor: '#388D3C', 
    fillOpacity: 0.16,
    strokeColor: '#388D3C',
    strokeOpacity: 0.16,
    strokeWeight: 2,
    clickable: false,
    editable: false, 
    draggable: false,
};

export const circleOptionsAcceptable: google.maps.CircleOptions = {
    fillColor: '#FF8F00', 
    fillOpacity: 0.16,
    strokeColor: '#FF8F00',
    strokeOpacity: 0.16,
    strokeWeight: 2,
    clickable: false,
    editable: false, 
    draggable: false,
};


export const circleOptionsNotAcceptable: google.maps.CircleOptions = {
    fillColor: '#E53935', 
    fillOpacity: 0.16,
    strokeColor: '#E53935',
    strokeOpacity: 0.16,
    strokeWeight: 2,
    clickable: false,
    editable: false, 
    draggable: false,
};

export const mapOptions: google.maps.MapOptions = {
    disableDefaultUI: false,
    styles: DEFAULT_MAP_STYLES,
    scaleControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false
};