import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MapInfoWindow, MapMarker, GoogleMap, MapMarkerClusterer } from '@angular/google-maps';
import { ViewChild, ElementRef } from '@angular/core';
import { VisitEVVDetails} from '@app/models/patient/visit-details/visit.model';
import { AddressEntityData } from '@app/models/address.model';
import { SettingsService } from '@app/core/services/settings/settings.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { NotificationsService } from '@app/core/services';
import { SettingsSection } from '@app/models/settings/settings-section';
import { EVVStatus } from '@app/core/enums';
import { circleOptionsExcellent, circleOptionsAcceptable, circleOptionsNotAcceptable, mapOptions } from './google-map.config';

@UntilDestroy()
@Component({
  selector: 'app-visit-map',
  templateUrl: './visit-map.component.html',
  styleUrls: ['./visit-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitMapComponent implements OnInit {
  @Input() details: VisitEVVDetails;
  @Input() addressDetails: AddressEntityData;
  @ViewChild(GoogleMap) private googleMap: GoogleMap;
  @ViewChild('locationStartInfoWindow') locationStartInfoWindow!: MapInfoWindow;
  @ViewChild('locationEndInfoWindow') locationEndInfoWindow!: MapInfoWindow;

  readonly EVVStatus = EVVStatus;

  readonly clockInIcon = {
    url: 'assets/icons/clock-in.svg',
    scaledSize: { width: 16, height: 16 },
  };

  readonly clockOutIcon = {
    url: 'assets/icons/clock-out.svg',
    scaledSize: { width: 16, height: 16 },
  };

  readonly homeIcon = {
    url: 'assets/icons/visit-home.svg',
    scaledSize: { width: 24, height: 24 },
  };

  public mapCenter: google.maps.LatLngLiteral = { lat: 41, lng: -101 };
  readonly initialZoom = 18;

  public circleRadiusExcellent = 50;
  public circleRadiusAcceptable = 100;
  public circleRadiusNotAcceptable = 150;

  readonly circleOptionsExcellent: google.maps.CircleOptions = circleOptionsExcellent;
  readonly circleOptionsAcceptable: google.maps.CircleOptions = circleOptionsAcceptable
  readonly circleOptionsNotAcceptable: google.maps.CircleOptions = circleOptionsNotAcceptable;

  readonly mapOptions: google.maps.MapOptions = mapOptions;

  constructor(
    private settingsService: SettingsService,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.loadVisitRadiuses();
  }

  loadVisitRadiuses(): void {
    this.settingsService.getSettingsSection().pipe(untilDestroyed(this)).subscribe({
      next: (res: SettingsSection) => {
        if (res.evv_settings) {
          this.circleRadiusExcellent = this.feetToMeters(res.evv_settings.excellent_ft);
          this.circleRadiusAcceptable = this.feetToMeters(res.evv_settings.acceptable_ft);
          this.circleRadiusNotAcceptable = this.feetToMeters(res.evv_settings.not_acceptable_ft);
          this.cdr.markForCheck();
        }
      },
      error: (error) => this.notificationsService.showError(error)
    },)
  }

  changeCenter(location: google.maps.LatLngLiteral): void {
    this.mapCenter = location;
    if (this.googleMap) {
      this.googleMap.panTo(location); // Smoothly pans the map to the new center
    }
  }

  private feetToMeters(feet: number): number {
    return feet * 0.3048;
  }

  openLocationStartInfoWindow(marker: MapMarker): void {
    if (this.locationStartInfoWindow) {
      this.locationStartInfoWindow.open(marker);
    }
  }

  openLocationEndInfoWindow(marker: MapMarker): void {
    if (this.locationEndInfoWindow) {
      this.locationEndInfoWindow.open(marker);
    }
  }

}
