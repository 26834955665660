<mat-form-field appearance="fill" class="{{ size }}" [class.is-invalid]="invalid">

  <input
    hidden
    [min]="minDate"
    [max]="maxDate"
    [disabled]="disabled"
    [matDatepicker]="picker"
    [matDatepickerFilter]="datesFilter"
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="datepickerValue"
    (ngModelChange)="onSelectDateOnDatepicker($event)"
  />

  <input
    matInput
    [class.pointer]="!allowChangeTextInput"
    [imask]="{ mask: '00/00/0000' }"
    [readonly]="disabled || !allowChangeTextInput"
    [placeholder]="placeholder"
    [ngModel]="inputValue"
    (ngModelChange)="onInputValueChanged($event)"
    (click)="onInputClick(picker)"
    (blur)="onBlur()"/>

  <div class="icons" matSuffix>
    <mat-icon *ngIf="clearable && datepickerValue" class="close-icon" (click)="onInputValueChanged(null)">close</mat-icon>

    <mat-datepicker-toggle [for]="picker"
                           [disabled]="disabled"
                           [matTooltip]="toggleTooltip"
    ></mat-datepicker-toggle>
  </div>


  <mat-datepicker #picker xPosition="end" [restoreFocus]="false" (closed)="onBlur()"></mat-datepicker>

</mat-form-field>

