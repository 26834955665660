import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { PaginatedResponse } from '@app/models/paginated-response.model';
import { DirectChatPayload, GetChatsRequestParams, GroupChatPayload, UserChat } from '../models/chat.model';
import { MESSAGE_CENTER_URLS } from '../shared/MESSAGE_CENTER_URLS';
import { MCProfile, User } from '../models/user.model';
import { UnreadMessages } from '../models/message.model';
import { CommunicationMode } from '@app/pages/message-center/models/communication.model';
import { HttpService, RealtimeService } from '@app/core/services';
import { WsEvent } from '@app/core/services/websocket/ws.models';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterService {
  public messageCenterToggle = new EventEmitter<boolean>();
  public openChat$ = new EventEmitter<UserChat>();
  public closeChat$ = new EventEmitter<number>();
  public closeAllChats$ = new EventEmitter<void>();

  constructor(private http: HttpService,
              private realtimeService: RealtimeService,
              ) { }

  closeMessageCenter(): void {
    this.messageCenterToggle.emit(false);
  }

  openChatByUserId(userId: number): Observable<UserChat> {
    return this.getChatIdByUserId(userId).pipe(
      switchMap((res) => this.getUserChatById(res.chat)),
      tap(chat => this.openChat$.emit(chat))
    );
  }

  getUsers(query: string = ''): Observable<PaginatedResponse<MCProfile>> {
    return <Observable<PaginatedResponse<MCProfile>>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_account }${ query }`);
  }

  getUsersWidget(params: any): Observable<PaginatedResponse<User>> {
    return this.http.GET<PaginatedResponse<User>>(MESSAGE_CENTER_URLS.user_account, params);
  }

  getUsersChats(params?: GetChatsRequestParams): Observable<PaginatedResponse<UserChat>> {
    return this.http.GET<PaginatedResponse<UserChat>>(MESSAGE_CENTER_URLS.user_chat, params);
  }

  getUserAccount(userId: number, query: string = ''): Observable<MCProfile> {
    return <Observable<MCProfile>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_account }${ userId }/${ query }`);
  }

  createDirectChat(body: DirectChatPayload): Observable<UserChat> {
    return <Observable<UserChat>>this.http.POST(MESSAGE_CENTER_URLS.create_direct_chat, body).pipe(
      tap((response) =>  this.realtimeService.emitInternalEvent({ event: WsEvent.newChat, payload: response }))
    );
  }

  createGroupChat(body: GroupChatPayload): Observable<UserChat> {
    return this.http.POST<UserChat>(MESSAGE_CENTER_URLS.create_group_chat, body).pipe(
      tap((response) =>  this.realtimeService.emitInternalEvent({ event: WsEvent.newChat, payload: response }))
    );
  }

  getUserChatById(chatId: number): Observable<UserChat> {
    return <Observable<UserChat>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_chat }${ chatId }/`);
  }

  updateGroupChat(chatId: number, body: GroupChatPayload): Observable<UserChat> {
    return this.http.PUT<UserChat>(`${ MESSAGE_CENTER_URLS.user_chat }${ chatId }/update_group_chat/`, body).pipe(
      tap((response) =>  this.realtimeService.emitInternalEvent({ event: WsEvent.chatUpdating, payload: response }))
    );
  }

  deleteUserChat(userChatId: number): Observable<any> {
    return <Observable<any>>this.http.DELETE(`${ MESSAGE_CENTER_URLS.user_chat }${ userChatId }/`).pipe(
      tap(res => this.realtimeService.emitInternalEvent({ event: WsEvent.deleteChat, payload: { id: userChatId } })),
    );
  }

  getUnreadMessages(): Observable<UnreadMessages> {
    return <Observable<UnreadMessages>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_chat }all_unread_messages/`);
  }

  getChatIdByUserId(userId: number): Observable<{ chat: number }> {
    return this.http.POST(MESSAGE_CENTER_URLS.external_link, {
      communication_mode: CommunicationMode.Chat,
      user_to: userId
    });
  }
}
