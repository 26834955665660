export type LanguageDataType = Record<string, Record<string, string>>;

export interface Translation {
    id?: number;
    scope: number;
    scope_value?: string;
    company: number;
    language_code: string;
    language_name: string;
    language_data?: LanguageDataType;
    language_file?: string;
    is_default?: boolean;
    is_custom?: boolean;
}

export interface QueryParamsTranslation {
    scopes?: TranslationScope[];
    scope?: TranslationScope;
    widget?: string; 
    no_limits?: boolean | string;
}

export enum TranslationScope {
    Front = 0,
    App = 1,
    Back = 2
}