import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '@app/shared/urls';
import { HttpService } from './http/http.service';
import { ObservableCache } from '../cache';
import { QueryBuilder } from '../query-builder';
import { PaginationParams } from '@app/shared/interfaces/pagination.class';

type DictionaryType = 'country' | 'medicine' | 'disease' | 'visit_note_reason' | 'visit_note_action';

export interface DictionaryParams extends PaginationParams {
  dictionary: DictionaryType | DictionaryType[];
  widget?: 'fk';
  pk?: number | string; // search by id
  search?: string;
  no_limits?: boolean;
  paginate?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {
  private cache = new ObservableCache<any>();

  constructor(private http: HttpService) {
  }

  getDictionary<T>(params: DictionaryParams): Observable<T> {
    const url = `${ URLS.dictionary }?${ QueryBuilder.buildFromObject(params) }`;

    return this.cache.get(url, this.http.GET<T>(url));
  }
}
