<form [formGroup]="form">
  <div class="form__item notes">
    <span class="form__label">{{ 'visitDetails.tabVisit_field_note' | translate }}</span>
    <div class="form__value textarea-field">
      <div class="flex-grow-1">
        <textarea
          placeholder="{{ 'visitDetails.tabVisit_field_note_placeholder' | translate }}"
          class="form-control"
          formControlName="text"
          #textControl="ngControl"
          autocomplete="off"
          maxlength="1000"
          [class.is-invalid]="textControl.touched && textControl.invalid"
          [matTooltip]="'visitDetails.tabVisit_field_note_tooltip' | translate"
        ></textarea>

        <app-errors [field]="textControl"></app-errors>
      </div>

      <ng-container *ngIf="textControl.value">
        <button class="action__btn" matRipple (click)="submit()">
          <mat-icon class="material-icons-two-tone icon-green">done</mat-icon>
        </button>

        <button class="action__btn" matRipple (click)="resetForm()">
          <mat-icon class="material-icons-two-tone icon-red">close</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>

</form>
