export interface EdiProvider {
  id?: number;
  provider_type: EdiProviderType;
  provider_type_value?: string;
  account_id: number;
  account_name: string;
  account_secret: string;
  tax_identification_number: number;
  contact_person: string;
  contact_phone: string;
  contact_address: string;
  contact_state: string;
  contact_city: string;
  contact_zip: string;
}

export interface EdiProviderDetails {
  id: number;
  provider_type: EdiProviderType;
  provider_type_value: string;
  processing_methods: EdiProviderProcessingMethod[];
}

export interface EdiProviderParams {
  no_limits?: boolean;
  processing_methods?: EdiProviderProcessingMethod;
}

export interface EdiPartner {
  id: number;
  name: string;
  payer_id: number;
  provider_type: EdiProviderType;
  provider_type_value: string;
  claim_type: EdiClaimType;
  claim_type_value: string;
}

export interface EdiPartnerDetails {
  id: number;
  claim_type: EdiClaimType;
  claim_type_value: string;
  name: string;
  payer_id: string;
  provider_type: EdiProviderType;
  provider_type_value: string;
}

export interface EdiPartnerParams {
  no_limits?: boolean;
  provider_types?: number[];
}

export enum EdiProviderType {
  Waystar,
  CHC
}

export enum EdiClaimType {
  Professional,
  Institutional
}

export enum EdiProviderProcessingMethod {
  Automatically,
  Manually
}

export interface EdiProviderDialogData {
  mode: string;
  title: string;
  buttonLabel: string;
  provider?: EdiProvider;
}