import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { URLS } from '@app/shared/urls';
import { GetVisitsRequestParams, TableVisitItem, VisitTimeLog } from '@app/models/visits/visits.model';
import { EditVisitData } from '@app/models/visits/edit-visit-data.model';
import { CaregiverLocationInfo } from '@app/models/visits/caregiver-location-info.model';
import { getUtcOffset } from '@app/common';

@Injectable({
  providedIn: 'root'
})
export class VisitsService {

  recentlyCreatedVisits: Subject<EditVisitData> = new Subject<EditVisitData>();

  constructor(
    private http: HttpService
  ) { }

  getVisits(params?: GetVisitsRequestParams): Observable<PaginatedResponse<TableVisitItem>> {
    return this.http.GET<PaginatedResponse<TableVisitItem>>(URLS.patients_visit, {
      ...(params ?? {}),
      utc_offset: getUtcOffset()
    });
  }

  updateVisitById(visitId: number, data): Observable<EditVisitData> {
    return this.http.PUT<EditVisitData>(`${ URLS.patients_visit }${ visitId }/`, data);
  }

  getVisitById(visitId: number): Observable<EditVisitData> {
    return this.http.GET<EditVisitData>(`${ URLS.patients_visit }${ visitId }/`);
  }

  getVisitTimeLogs(visitId: number): Observable<VisitTimeLog> {
    return this.http.GET<VisitTimeLog>(`${ URLS.patients_visit }${ visitId }/time_logs/`);
  }

  getCaregiverLocationInfo(visitId: number): Observable<CaregiverLocationInfo> {
    return this.http.GET<CaregiverLocationInfo>(`${ URLS.patients_visit }${ visitId }/caregiver_location_info/`);
  }
}
