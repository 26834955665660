import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { Allergy, GetAllergiesRequestParams } from '@app/models/users/allergies.model';

@Injectable({
  providedIn: 'root'
})
export class AllergiesService {

  constructor(
    private http: HttpService
  ) { }

  getAllergies(params: GetAllergiesRequestParams): Observable<PaginatedResponse<Allergy>> {
    return this.http.GET<PaginatedResponse<Allergy>>(URLS.allergy_info, params);
  }

  addAllergy(allergy: Allergy): Observable<Allergy> {
    return this.http.POST<Allergy>(`${ URLS.allergy_info }`, allergy);
  }

  editAllergy(allergy: Allergy): Observable<Allergy> {
    return this.http.PUT<Allergy>(`${ URLS.allergy_info }${ allergy.id }/`, allergy);
  }

  deleteAllergy(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.allergy_info }${ id }/`);
  }
}
