import { Observable } from 'rxjs';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { map } from 'rxjs/operators';

export function mergeWithPaginatedResponse<T = any>(itemsForMerge: T[]): (source$: Observable<PaginatedResponse<T>>) => Observable<PaginatedResponse<T>> {
  return source$ => source$.pipe(
    map((response) => {
      return {
        results: [...(itemsForMerge ?? []), ...response.results],
        count: response.count + (itemsForMerge ?? []).length,
      };
    })
  );
}

