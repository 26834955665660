import { BatchStatus, GeneralStatus } from '@app/core/enums';
import { StatusesMapper } from '@app/models/statuses-mapper.model';

export const BATCH_STATUSES_MAPPER: StatusesMapper = {
  [BatchStatus.New]: GeneralStatus.Applicant,
  [BatchStatus.Ready]: GeneralStatus.Scheduled,
  [BatchStatus.Submitted]: GeneralStatus.Active,
  [BatchStatus.Accepted]: GeneralStatus.Coordinated,
  [BatchStatus.Rejected]: GeneralStatus.Expired,
  [BatchStatus.Approved]: GeneralStatus.Open,
  [BatchStatus.Denied]: GeneralStatus.Cancelled,
  [BatchStatus.Pended]: GeneralStatus.Discharged,
  [BatchStatus.Voided]: GeneralStatus.HospitalizedAdmitted,
  [BatchStatus.Paid]: GeneralStatus.Coordinated,
  [BatchStatus.PartialPaid]: GeneralStatus.Discharged,
  [BatchStatus.NotBilled]: GeneralStatus.Expired,
  [BatchStatus.Billed]: GeneralStatus.EligibleForRehire,
  [BatchStatus.Open]: GeneralStatus.PendingOffer,
  [BatchStatus.Closed]: GeneralStatus.Hold,
  [BatchStatus.Incomplete]: GeneralStatus.DeclinedOffer,
  [BatchStatus.Completed]: GeneralStatus.EnRoute,
  [BatchStatus.NotPaid]: GeneralStatus.Missed,
  [BatchStatus.ReadyToRun]: GeneralStatus.Scheduled,
}