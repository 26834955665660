<app-loading *ngIf="isLoading" class="loading"></app-loading>

<div *ngIf="visitData && !isLoading" class="visit-details">
  <form
    class="visit-details__form form"
    novalidate
    autocomplete="off"
    [formGroup]="form">

    <section class="visit-info">
      <div class="section-title">{{ 'visitDetails.tabVisit_group_visitInfo' | translate }}</div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_scheduledTime' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabVisit_field_scheduledTime_tooltip' | translate">
          {{ visitData.start_date_time | date: 'hh:mm a' }} - {{ visitData.end_date_time | date: 'hh:mm a' }}
          ({{ visitData.scheduled_duration }})
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_visitStartTime' | translate }}</span>
        <div class="form__value editable-field status-field">

          <div [matTooltip]="'visitDetails.tabVisit_field_makeClockIn_tooltip' | translate">
            <img
              src="/assets/icons/user-clock.svg"
              alt="change status"
              class="icon-action icon-blue"
              [class.disabled]="visitStatus !== VisitStatus.scheduled && visitStatus !== VisitStatus.missed && visitStatus !== VisitStatus.enRoute"
              (click)="changeStatus(VisitStatus.inProgress)">
          </div>

          <app-date-time-field
            [dateTooltip]="'visitDetails.tabVisit_field_visitStartTime_tooltip' | translate"
            [editTooltip]="'visitDetails.tabVisit_field_visitStartTime_editTooltip' | translate"
            [initialDateTime]="visitStart.value"
            (dateTimeChanged)="visitStartTimeChange($event)"
          ></app-date-time-field>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_visitEndTime' | translate }}</span>
        <div class="form__value editable-field status-field">
          <div [matTooltip]="'visitDetails.tabVisit_field_makeClockOut_tooltip' | translate">
            <img
              src="/assets/icons/user-clock.svg"
              alt="change status"
              class="icon-action icon-green"
              [class.disabled]="visitStatus !== VisitStatus.inProgress"
              (click)="changeStatus(VisitStatus.completed)">
          </div>

          <app-date-time-field
            [dateTooltip]="'visitDetails.tabVisit_field_visitEndTime_tooltip' | translate"
            [editTooltip]="'visitDetails.tabVisit_field_visitEndTime_editTooltip' | translate"
            [initialDateTime]="visitEnd.value"
            (dateTimeChanged)="visitEndTimeChange($event)"
          ></app-date-time-field>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_visitDuration' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabVisit_field_visitDuration_tooltip' | translate">
          {{ visitData.visit_duration }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabVisit_field_overTime" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabVisit_field_overTime_tooltip' | translate">
          {{ doubleNumber(visitData.overtime_hours) }}:{{ doubleNumber(visitData.overtime_minutes) }}
        </div>
      </div>

      <ng-container *ngIf="visitData.evv_details && (visitData.evv_details.start_date_time || visitData.evv_details.end_date_time); else noEvvDetails">
        <div class="expandable-panel" [expanded]="false" expandablePanel #assignEvvDetailsPanel="appExpandablePanel">
          <div class="panel-name" (click)="assignEvvDetailsPanel.toggle()">
            <div class="form__item" >
              <span class="form__label">{{ 'visitDetails.tabVisit_field_evvCallInOut' | translate }}</span>
              <div class="form__value">
                <div>
                  <ng-container *ngIf="visitData.evv_details.start_date_time">
                    <span class="clock-in-out"> {{ 'visitDetails.tabVisit_field_clock-in' | translate }} </span>
                    <svg-icon src="/assets/icons/clock-in.svg"
                              class="clock-in"
                              matTooltipClass="mat-tooltip_pre-line mat-tooltip_interactable"
                              matTooltip="{{ 'visitDetails.tabVisit_evv-gps' | translate }}: {{ visitData.evv_details.start_location_point?.lat || '-' }}, {{ visitData.evv_details.start_location_point?.lng }}
                            {{ 'visitDetails.tabVisit_evv-address' | translate }}: {{ visitData.evv_details.start_location_address || '-' }}"
                    ></svg-icon>
                    <span *ngIf="visitData.evv_details" class="evv-date"> {{ visitData.evv_details.start_date_time | date: 'MM/dd/YYYY hh:mm a' }} </span>
                  </ng-container>
      
                  <ng-container *ngIf="visitData.evv_details.end_date_time">
                    <span class="clock-in-out"> {{ 'visitDetails.tabVisit_field_clock-out' | translate }} </span>
                    <svg-icon src="/assets/icons/clock-out.svg"
                              class="clock-out"
                              matTooltipClass="mat-tooltip_pre-line mat-tooltip_interactable"
                              matTooltip="{{ 'visitDetails.tabVisit_evv-gps' | translate }}: {{ visitData.evv_details.end_location_point?.lat || '-' }}, {{ visitData.evv_details.end_location_point?.lng }}
                              {{ 'visitDetails.tabVisit_evv-address' | translate }}: {{ visitData.evv_details.end_location_address || '-' }}"
                    ></svg-icon>
                  </ng-container>
      
                  <span *ngIf="visitData.evv_details" class="evv-date"> {{ visitData.evv_details.end_date_time | date: 'MM/dd/YYYY hh:mm a' }} </span>
                </div>
              </div>
            </div>
          </div>

          <div (click)="assignEvvDetailsPanel.toggle()" class="icon-arrow-wrapper">
            <span class="icon-arrow"></span>
          </div>

          <div panelContent>
            <div class="panel-content">
              <app-visit-map [addressDetails]="visitData.address_detail" [details]="visitData.evv_details"></app-visit-map>
              <div *ngIf="visitData.evv_details.start_location_point || visitData.evv_details.end_location_point" class="visit-map-info">
                <p class="address">{{ 'visitDetails.tabVisit_map_patientAddress' | translate }}: {{ visitData.address_detail?.full_address }}</p>
                <div class="info-wrapper">
                  <div *ngIf="visitData.evv_details.start_location_point" class="item">
                    <p class="info-title">{{ 'visitDetails.tabVisit_map_clockIn' | translate }}</p>
                    <p class="info-gps">
                      <span>{{ 'visitDetails.tabVisit_map_gps' | translate }}:</span>
                      <span class="coordinates" (click)="map.changeCenter(visitData.evv_details.start_location_point)">
                        {{ visitData.evv_details.start_location_point.lat | number: '1.0-6' }}, {{ visitData.evv_details.start_location_point.lng | number: '1.0-6' }}
                        <img class="map-icon" src="/assets/icons/map-icon.svg" alt="">
                      </span>
                    </p>
                    <div class="info-status-distance">
                      <div class="info-status">
                        <span>
                          {{ 'visitDetails.tabVisit_map_status' | translate }}: 
                        </span>
                        <ng-container [ngSwitch]="visitData.evv_details.start_distance_status">
                          <ng-container *ngSwitchCase="EVVStatus.Excellent">
                            <span class="status_excellent">
                              {{ 'visitDetails.tabVisit_map_status_excellent' | translate }}
                            </span>
                          </ng-container>
                        
                          <ng-container *ngSwitchCase="EVVStatus.Acceptable">
                            <span class="status_acceptable">
                              {{ 'visitDetails.tabVisit_map_status_acceptable' | translate }}
                            </span>
                          </ng-container>
                        
                          <ng-container *ngSwitchCase="EVVStatus.NotAcceptable">
                            <span class="status_notAcceptable">
                              {{ 'visitDetails.tabVisit_map_status_notAcceptable' | translate }}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="info-distance">
                        <span>
                          {{ 'visitDetails.tabVisit_map_distance' | translate }}: 
                        </span>
                        <span 
                          class="distance" 
                          [ngClass]="getStatusClass(visitData.evv_details.start_distance_status)">
                          {{ visitData.evv_details.start_distance_ft | number:'1.0-1' }} {{ 'visitDetails.tabVisit_map_ft' | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="visitData.evv_details.end_location_point" class="item">
                    <p class="info-title">{{ 'visitDetails.tabVisit_map_clockOut' | translate }}</p>
                    <p class="info-gps">
                      <span>{{ 'visitDetails.tabVisit_map_gps' | translate }}:</span>
                      <span class="coordinates" (click)="map.changeCenter(visitData.evv_details.start_location_point)">
                        {{ visitData.evv_details.end_location_point.lat | number: '1.0-6' }}, {{ visitData.evv_details.end_location_point.lng | number: '1.0-6' }}
                        <img class="map-icon" src="/assets/icons/map-icon.svg" alt="">
                      </span>
                    </p>
                    <div class="info-status-distance">
                      <div class="info-status">
                        <span>
                          {{ 'visitDetails.tabVisit_map_status' | translate }}: 
                        </span>
                        <ng-container [ngSwitch]="visitData.evv_details.end_distance_status">
                          <ng-container *ngSwitchCase="EVVStatus.Excellent">
                            <span class="status_excellent">
                              {{ 'visitDetails.tabVisit_map_status_excellent' | translate }}
                            </span>
                          </ng-container>
                        
                          <ng-container *ngSwitchCase="EVVStatus.Acceptable">
                            <span class="status_acceptable">
                              {{ 'visitDetails.tabVisit_map_status_acceptable' | translate }}
                            </span>
                          </ng-container>
                        
                          <ng-container *ngSwitchCase="EVVStatus.NotAcceptable">
                            <span class="status_notAcceptable">
                              {{ 'visitDetails.tabVisit_map_status_notAcceptable' | translate }}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="info-distance">
                        <span>
                          {{ 'visitDetails.tabVisit_map_distance' | translate }}: 
                        </span>
                        <span 
                          class="distance" 
                          [ngClass]="getStatusClass(visitData.evv_details.end_distance_status)">
                          {{ visitData.evv_details.end_distance_ft | number:'1.0-1' }} {{ 'visitDetails.tabVisit_map_ft' | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </ng-container>
      <ng-template #noEvvDetails>
        <div class="form__item">
          <span class="form__label">{{ 'visitDetails.tabVisit_field_evvCallInOut' | translate }}</span>
          <div class="form__value">
            <div>-</div>
          </div>
        </div>
      </ng-template>
    </section>

    <section class="visit-verification">
      <div class="section-title">{{ 'visitDetails.tabVisit_group_visitVerification' | translate }}</div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_supervisor' | translate }}</span>
        <div class="form__value select">
          <ng-select
            class="app-ng-select"
            bindLabel="label"
            bindValue="value"
            appendTo="body"
            appearance="outline"
            formControlName="supervisor"
            placeholder="Select"
            [matTooltip]="'visitDetails.tabVisit_field_supervisor_tooltip' | translate"
            [items]="supervisorList"
            [clearable]="false">
          </ng-select>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_verifiedBy' | translate }}</span>
        <div class="form__value select">
          <ng-select
            class="app-ng-select"
            bindLabel="label"
            bindValue="value"
            appendTo="body"
            appearance="outline"
            formControlName="verified_by"
            placeholder="Select"
            [matTooltip]="'visitDetails.tabVisit_field_verifiedBy_tooltip' | translate"
            [items]="verifiedList"
            [clearable]="false"
            [searchable]="false">
          </ng-select>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_timeVerified' | translate }}</span>
        <div class="form__value editable-field">
          <app-date-time-field
            [editTooltip]="'visitDetails.tabVisit_field_timeVerified_editTooltip' | translate"
            [initialDateTime]="timeVerified.value"
            (dateTimeChanged)="verificationDateChange($event)"
          ></app-date-time-field>
        </div>
      </div>
    </section>

    <section class="notes">
      <div class="section-title">{{ 'visitDetails.tabVisit_group_notes' | translate }}</div>

      <app-visit-note-form (addNote)="addNote($event)"></app-visit-note-form>

      <div class="notes-table">
        <div class="notes-table__title">{{ 'visitDetails.tabVisit_field_lastNotes' | translate }}</div>
        <div *ngIf="!notesList.length" class="no-data">{{ 'visitDetails.tabVisit_notification_noLastNotes' | translate }}</div>
        <div *ngIf="notesList.length" class="notes-table__row header">
          <span class="notes-table__date">{{ 'visitDetails.tabVisit_field_date' | translate }}</span>
          <span class="notes-table__notes">{{ 'visitDetails.tabVisit_field_notes' | translate }}</span>
          <span class="notes-table__user">{{ 'visitDetails.tabVisit_field_user' | translate }}</span>
        </div>
        <div *ngFor="let note of notesList" class="notes-table__row">
          <span class="notes-table__date">{{ note.date | date: 'MM/dd/YYYY hh:mm a' }}</span>
          <span class="notes-table__notes">{{ note.notes }}</span>
          <span class="notes-table__user">{{ note.user }}</span>
        </div>
      </div>
    </section>

    <section class="poc" expandablePanel #POCPanel="appExpandablePanel" [expanded]="false">
      <div class="section-title" (click)="POCPanel.toggle()">
        <span>{{ 'visitDetails.tabVisit_group_planOfCare' | translate }}</span>
        <mat-icon class="expand-icon" [class.opened]="POCPanel.expanded">arrow_drop_down</mat-icon>
      </div>

      <div class="poc__section" panelContent>
        <div *ngIf="!pocList?.length" class="no-data">{{ 'visitDetails.tabVisit_notification_noPlanOfCare' | translate }}</div>
        <ng-container *ngIf="pocList?.length">
          <div class="poc__amount">
            <div>
              {{ 'visitDetails.tabVisit_field_dutySheetPOC' | translate }}:
              <span class="poc__value"
                    [matTooltip]="'visitDetails.tabVisit_field_dutySheetPOC_tooltip' | translate"
              >{{ pocList?.length }}</span>
            </div>
            <div>
              {{ 'visitDetails.tabVisit_field_visitDuration' | translate }}:
              <span class="poc__value" [matTooltip]="'visitDetails.tabVisit_field_visitDuration_tooltip' | translate">
                ({{ visitsDuration }})
              </span>
            </div>
            <div>{{ 'visitDetails.tabVisit_field_total' | translate }}:
              <span class="poc__value"
                    [matTooltip]="'visitDetails.tabVisit_field_total_dutiesTooltip' | translate">
                {{ pocList?.length }} ({{ visitsDuration }})
              </span>
            </div>
          </div>

          <div class="poc__table">
            <div class="poc__row header">
              <span class="poc__id">{{ 'visitDetails.tabVisit_pocTable_column_id' | translate }}</span>
              <span class="poc__category">{{ 'visitDetails.tabVisit_pocTable_column_category' | translate }}</span>
              <span class="poc__duty">{{ 'visitDetails.tabVisit_pocTable_column_duty' | translate }}</span>
              <span class="poc__minutes">{{ 'visitDetails.tabVisit_pocTable_column_minutes' | translate }}</span>
              <span class="poc__done">{{ 'visitDetails.tabVisit_pocTable_column_done' | translate }}</span>
              <span class="poc__not-done">{{ 'visitDetails.tabVisit_pocTable_column_notDone' | translate }}</span>
            </div>

            <div *ngFor="let poc of pocList" class="poc__row">
              <span class="poc__id" [matTooltip]="'visitDetails.tabVisit_pocTable_column_id_tooltip' | translate">{{ poc.dutyCode }}</span>
              <span class="poc__category" [matTooltip]="'visitDetails.tabVisit_pocTable_column_category_tooltip' | translate">{{ poc.categoryName }}</span>
              <span class="poc__duty" [matTooltip]="'visitDetails.tabVisit_pocTable_column_duty_tooltip' | translate">{{ poc.dutyName }}</span>
              <span class="poc__minutes" [matTooltip]="'visitDetails.tabVisit_pocTable_column_minutes_tooltip' | translate">{{ poc.minutes }}</span>
              <span class="poc__done">
              <mat-checkbox
                class="app-checkbox"
                [checked]="poc.done"
                [matTooltip]="'visitDetails.tabVisit_pocTable_column_done_tooltip' | translate"
                (change)="changePOCCheckbox($event.checked, poc.id, 'done')"
              ></mat-checkbox>
            </span>
              <span class="poc__not-done">
              <mat-checkbox
                class="app-checkbox"
                [checked]="poc.not_done"
                [matTooltip]="'visitDetails.tabVisit_pocTable_column_notDone_tooltip' | translate"
                (change)="changePOCCheckbox($event.checked, poc.id, 'not_done')"
              ></mat-checkbox>
            </span>
            </div>
          </div>
        </ng-container>
      </div>
    </section>

    <section expandablePanel #IVRPanel="appExpandablePanel" [expanded]="false">
      <div class="section-title" (click)="IVRPanel.toggle()">
        <span>{{ 'visitDetails.tabVisit_ivr_title' | translate }}</span>
        <mat-icon class="expand-icon" [class.opened]="IVRPanel.expanded">arrow_drop_down</mat-icon>
      </div>

      <div panelContent>
        <div class="ivr-content">
        <div class="column">
          <p class="bold">{{ 'visitDetails.tabVisit_ivr_clockIn_title' | translate }}</p>
          <p>{{ 'visitDetails.tabVisit_ivr_clockIn_phone' | translate }} <span class="bold">{{ visitData.ivr_phone_number }}</span> </p>
          <p>
           <span>{{ 'visitDetails.tabVisit_ivr_clockIn_press' | translate }} " <span class="bold">1</span> "</span>
            <br>
           <span> {{ 'visitDetails.tabVisit_ivr_clockIn_enterId' | translate }} " <span class="bold">{{ visitData.ivr_caregiver_identifier }}</span> "</span>
          </p>

          <span class="success">{{ 'visitDetails.tabVisit_ivr_clockIn_success' | translate }}</span>
        </div>

        <div class="column">
          <p class="bold">{{ 'visitDetails.tabVisit_ivr_clockOut_title' | translate }}</p>
          <p>{{ 'visitDetails.tabVisit_ivr_clockOut_phone' | translate }} <span class="bold">{{ visitData.ivr_phone_number }}</span> </p>
          <p>
            <span>{{ 'visitDetails.tabVisit_ivr_clockOut_press' | translate }} " <span class="bold">2</span> "</span>
            <br>
            <span> {{ 'visitDetails.tabVisit_ivr_clockOut_enterId' | translate }} " <span class="bold">{{ visitData.ivr_caregiver_identifier }}</span> "</span>
          </p>

          <ul *ngIf="pocList?.length">
            {{ 'visitDetails.tabVisit_ivr_clockOut_dutyCodes_title' | translate }}
            <li *ngFor="let item of pocList">
              <span class="bold duty-number">- *{{ item.dutyCode }} </span> {{ item.dutyName }}
            </li>
          </ul>

          <p> To finish dial " <span class="bold">0000</span> " </p>

          <span class="success">{{ 'visitDetails.tabVisit_ivr_clockOut_success' | translate }}</span>
        </div>
      </div>
      </div>
    </section>
  </form>

  <div class="visit-details__save">
    <button
      matRipple
      class="app-button app-button_blue app-button_spinner"
      [disabled]="!form.valid || form.pristine || isSaving"
      [matTooltip]="'visitDetails.tabVisit_buttonSave_tooltip' | translate"
      (click)="save()">
      <mat-spinner *ngIf="isSaving" class="spinner" diameter="14" strokeWidth="1"></mat-spinner>
      {{ 'buttons.save' | translate }}
    </button>
  </div>
</div>
