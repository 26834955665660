import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { toPaginatedResponse } from '@app/core/rx-operators';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { EdiProvider, EdiProviderParams } from '@app/models/settings/billing/edi-provider.model';
import { BillingService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { ObservableCache } from '../cache';
import { QueryBuilder } from '../query-builder';

const cache = new ObservableCache<PaginatedResponse<EdiProvider>>();
const CACHE_KEY: string = 'ediProviders';

export class EdiProvidersOptionsLoader implements OptionsLoader {
  private billingService: BillingService = inject<BillingService>(BillingService);
  
  getOptions(params?: EdiProviderParams): Observable<PaginatedResponse<EdiProvider>> {
    return cache.get(`${ CACHE_KEY }_${ QueryBuilder.buildFromObject(params) }`, this.billingService.getEdiProviders(params).pipe(toPaginatedResponse()));
  }
}