import { PaginationParams } from '@app/shared/interfaces/pagination.class';
import { IdFullNameWidgetResponse, IdNameWidgetResponse } from '@app/models/response.model';
import { AddressDetailEntityData } from '@app/models/address.model';
import { BasicServiceDetail } from '@app/models/patient/edit-patient-data.model';
import { SortType } from '@app/shared/components/sort-column/sort-column.component';

export interface VisitTimeLog {
  available_timestamp: string;
  canceled_timestamp: string;
  confirmation_timestamp: string;
  offered_timestamp: string;
  requested_timestamp: string;
  scheduled_timestamp: string;
  missed_timestamp: string;
  en_route_timestamp: string;
  no_show_timestamp: string;
  in_progress_timestamp: string;
  completed_timestamp: string;
}

export enum VisitStatus {
  available,
  canceled,
  offered,
  requested,
  scheduled,
  missed,
  enRoute,
  noShow,
  inProgress,
  completed
}

export interface TableVisitItem {
  caregiver: number;
  caregiver_detail: IdFullNameWidgetResponse;
  start_date_time: string;
  end_date_time: string;
  id: number;
  number: string;
  patient: number;
  patient_address: number;
  payer: number;
  payer_detail: IdNameWidgetResponse;
  service_name: number;
  status: VisitStatus;
  status_value: string;
  patient_detail: VisitPatientDetail;
  service_name_detail: {
    id: number;
    service_detail: BasicServiceDetail;
  };
  address_detail: AddressDetailEntityData;
}

interface VisitPatientDetail {
  id: number;
  avatar: string;
  first_name: string;
  full_name: string;
  last_name: string;
}

export type GetVisitsRequestParams = PaginationParams & {
  [sortField in VisitsSortField]?: SortType;
} & {
  start_date?: string;
  end_date?: string;
  search?: string;
  lookup_field?: string;
  statuses?: VisitStatus | VisitStatus[];
};

export type VisitsSortField =
  'id' |
  'caregiver_name' |
  'start_date_time' |
  'end_date_time' |
  'patient_name' |
  'service_name' |
  'status_name' |
  'patient_address' |
  'payer_name';
