import { Directive, OnInit, ChangeDetectorRef, inject, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';
import { finalize, filter, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { LoadingHandler } from '@app/core/loading-handler';
import { WsEvent, WsData } from '@app/core/services/websocket/ws.models';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { PaginationIfc } from '@app/shared/interfaces/pagination.class';
import { ModuleSubscription, NotificationsService } from '@app/core/services';
import { getWsData } from '@app/core/services/websocket/rxjs-ws-operators';
import { ModuleSubscriptionParams, RealtimeService } from '@app/core/services/websocket/realtime.service';
import { RealtimeEventHandler, MergeStrategy } from '@app/core/components/items/models';
import { IdObject } from '@app/models/id-object';
import { toPaginatedResponse } from "@app/core/rx-operators";

@UntilDestroy()
@Directive()
export abstract class ItemsComponent<T extends IdObject, VM extends IdObject = T> implements OnInit, OnDestroy {
  items: VM[] = [];
  protected pagination: PaginationIfc = new PaginationIfc();
  loadingHandler: LoadingHandler = new LoadingHandler();

  protected notificationsService: NotificationsService = inject(NotificationsService);
  protected realtimeService: RealtimeService = inject(RealtimeService);
  protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  protected loadOnInit: boolean = true;
  protected responseMergeStrategy: MergeStrategy = MergeStrategy.Replace;
  protected realtimeMergeStrategy: MergeStrategy = MergeStrategy.Prepend;
  protected realtimeModules: ModuleSubscriptionParams[] = [];
  protected createItemRealtimeEvents: (WsEvent | RealtimeEventHandler<any, T>)[] = [];
  protected updateItemRealtimeEvents: (WsEvent | RealtimeEventHandler<any, T>)[] = [];
  protected deleteItemRealtimeEvents: (WsEvent | RealtimeEventHandler<any, T>)[] = [];
  protected defaultEventMapper = (data: WsData<any>) => data.payload.data;
  protected defaultEventFilter = (data: WsData<any>) => true;
  protected subscribedRealtimeModules: ModuleSubscription[] = [];

  protected abstract getItems(params?: any): Observable<T[] | PaginatedResponse<T>>;

  private subscription: Subscription;
  private isLoadFirstTime = true;

  get isLoading(): boolean {
    return this.loadingHandler.loading;
  }

  get isNoItems(): boolean {
    return !this.items?.length && !this.isLoading;
  }

  get isLoadedAllItems(): boolean {
    return this.items.length >= this.pagination.total;
  }

  ngOnInit(): void {
    if (this.loadOnInit) {
      this.loadItems();
    }

    this.subscribeToRealtime();
  }

  loadItems(params?: any): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    const finishLoading = this.loadingHandler.showLoading(this.isLoadFirstTime);
    this.cdr.markForCheck();

    this.subscription = this.getItems(params).pipe(
      finalize(() => {
        this.isLoadFirstTime = false;
        finishLoading();
        this.cdr.markForCheck(); // for case if loadingHandler was created outside and cdr inside 'finishLoading' refers to another component.
      }),
      toPaginatedResponse(),
      untilDestroyed(this)
    ).subscribe({
      next: (response: PaginatedResponse<T>) => this.handleResponseSuccess(response),
      error: (error: HttpErrorResponse) => this.handleResponseError(error)
    });
  }

  protected handleResponseSuccess(response: PaginatedResponse<T>): void {
    this.mergeItems(response.results, this.responseMergeStrategy);
    this.pagination.countChanged(response.count);

    this.cdr.markForCheck();
  }

  protected handleResponseError(error: HttpErrorResponse): void {
    this.notificationsService.showError(error);
    this.cdr.markForCheck();
  }

  protected subscribeToRealtime(): void {
    this.subscribedRealtimeModules = this.realtimeModules.map(module => this.realtimeService.subscribeToModule(module));

    this.bindActionToRealtimeEvents(this.createItemRealtimeEvents, this.createItem.bind(this));
    this.bindActionToRealtimeEvents(this.updateItemRealtimeEvents, this.updateItem.bind(this));
    this.bindActionToRealtimeEvents(this.deleteItemRealtimeEvents, this.deleteItem.bind(this));
  }

  protected bindActionToRealtimeEvents(events: (WsEvent | RealtimeEventHandler<any, T>)[], handler: (item: T) => void): void {
    const eventsHandlers: RealtimeEventHandler[] = events.map(event => isEventHandler(event) ? event : { name: event });
    this.realtimeService.messages$.pipe(
      getWsData(),
      map(message => ({ message, event: eventsHandlers.find(event => event.name === message.event) })),
      filter(data => !!data.event),
      filter(data => data.event.filter ? data.event.filter(data.message) : this.defaultEventFilter(data.message)),
      map((data) => data.event.mapper ? data.event.mapper(data.message) : this.defaultEventMapper(data.message)),
      untilDestroyed(this)
    ).subscribe(handler);
  }

  protected createItem(newItem: T): void {
    const itemIndex = this.items.findIndex(i => i.id === newItem.id);

    if (itemIndex === -1) {
      this.mergeItems([newItem], this.realtimeMergeStrategy);
      this.pagination.countChanged(this.pagination.total + 1);
      this.cdr.markForCheck();
    }
  }

  protected updateItem(newItem: Partial<T>): void {
    const itemIndex = this.items.findIndex(i => i.id === newItem?.id);

    if (itemIndex !== -1) {
      this.items[itemIndex] = this.transformItem({ ...this.revertTransformedItem(this.items[itemIndex]), ...newItem });
      this.items = this.sortItems([...this.items]);
      this.cdr.markForCheck();
    }
  }

  protected deleteItem(deletedItem: IdObject): void {
    const itemIndex = this.items.findIndex(i => i.id === deletedItem.id);

    if (itemIndex !== -1) {
      this.items = this.items.filter((item) => item.id !== deletedItem.id);
      this.pagination.countChanged(this.pagination.total - 1);
      this.cdr.markForCheck();
    }
  }

  protected mergeItems(newItems: T[], mergeStrategy: MergeStrategy): void {
    let items: VM[] = [];

    switch (mergeStrategy) {
      case MergeStrategy.Append:
        items = [...this.items, ...newItems.map((item) => this.transformItem(item))];
        break;
      case MergeStrategy.Prepend:
        items = [...newItems.map((item) => this.transformItem(item)), ...this.items];
        break;
      case MergeStrategy.Replace:
        items = newItems.map(this.transformItem.bind(this));
    }

    this.items = this.sortItems(items);
  }

  protected sortItems(items: VM[]): VM[] {
    return items;
  }

  protected transformItem(item: T): VM {
    return item as any as VM;
  }

  protected revertTransformedItem(item: VM): T {
    return item as any as T;
  }

  trackById(index: number, item: T): number | string {
    return item?.id;
  }

  ngOnDestroy(): void {
    this.subscribedRealtimeModules?.forEach(module => module.unsubscribe());
  }
}

function isEventHandler(event: WsEvent | RealtimeEventHandler): event is RealtimeEventHandler {
  return (<RealtimeEventHandler>event)?.name != null;
}
