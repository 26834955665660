import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IdNameWidgetResponse } from '@app/models/response.model';
import { HttpService } from '../services';
import { URLS } from '@app/shared/urls';
import { QueryBuilder } from '../query-builder';
import { ObservableCache } from '../cache';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { mergeWithPaginatedResponse, toPaginatedResponse } from '@app/core/rx-operators';

const cache = new ObservableCache<PaginatedResponse<IdNameWidgetResponse>>();

export class LanguagesOptionsLoader implements OptionsLoader {
  private httpService = inject(HttpService);

  constructor(
    private additionalOptions?: IdNameWidgetResponse[]
  ) {}

  getOptions(params?: object): Observable<PaginatedResponse<IdNameWidgetResponse>> {
    const url = `${ URLS.language }?${ QueryBuilder.buildFromObject(params) }`;
    const request = this.httpService.GET<PaginatedResponse<IdNameWidgetResponse>>(url).pipe(
      toPaginatedResponse(),
      mergeWithPaginatedResponse(this.additionalOptions)
    );

    return cache.get(url, request);
  }
}
