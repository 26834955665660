import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { URLS } from '@app/shared/urls';
import { CategoryPayload, DutyCategory, GetCategoryRequestParams } from '@app/models/duties/category.model';
import { CreateDutyPayload, Duty, EditDutyPayload, GetDutiesRequestParams } from '@app/models/duties/duties.model';

@Injectable({
  providedIn: 'root'
})
export class DutiesService {

  constructor(
    private http: HttpService
  ) { }

  getDuties(params: GetDutiesRequestParams): Observable<PaginatedResponse<Duty>> {
    return this.http.GET<PaginatedResponse<Duty>>(URLS.duty, params);
  }

  addDuty(payload: CreateDutyPayload): Observable<Duty> {
    return this.http.POST<Duty>(URLS.duty, payload);
  }

  updateDuty(payload: EditDutyPayload): Observable<Duty> {
    return this.http.PUT<Duty>(`${ URLS.duty }${ payload.id }/`, payload);
  }

  getCategories(params: GetCategoryRequestParams): Observable<PaginatedResponse<DutyCategory>> {
    return this.http.GET<PaginatedResponse<DutyCategory>>(URLS.duty_category, params);
  }

  createCategory(payload: CategoryPayload): Observable<DutyCategory> {
    return this.http.POST<DutyCategory>(URLS.duty_category, payload);
  }

  updateCategory(payload: CategoryPayload): Observable<DutyCategory> {
    return this.http.PUT<DutyCategory>(`${ URLS.duty_category }${ payload.id }/`, payload);
  }
}
