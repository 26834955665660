import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomTranslateLoader } from '@app/shared/custom-translate.loader';
import { HttpClient } from '@angular/common/http';
import { URLS } from '@app/shared/urls';
import { Language } from '@app/models/language.model';
import { LanguageSettingsService, AuthService, NotificationsService } from '@app/core/services';
import { QueryParamsTranslation, TranslationScope } from '@app/pages/language-settings/models/translation.model';


const languages = [
  { name: 'languages.en_us', langCode: 'en-us' },
  { name: 'languages.en_gb', langCode: 'en-gb' },
  { name: 'languages.es', langCode: 'es' }
];

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly STORAGE_KEY = 'language';
  private readonly STORAGE_KEY_BACK = 'language-back';
  private readonly DEFAULT_LANGUAGE_CODE = 'en-us';

  private isInited: boolean = false;
  private languagesList = languages || [];
  currentLang: Language = new Language();

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private auth: AuthService,
    private languagesSettingsService: LanguageSettingsService,
    private notificationsService: NotificationsService
  ) { }

  init(): void {
    if (this.isInited) {
      return;
    }
    this.isInited = true;
    this.initializeTranslations();
    this.getSavedLanguage();
  }

  switchLanguage(langCode: string): void {
    if (this.currentLang && this.currentLang.langCode === langCode) {
      return;
    }
    this.translate.use(langCode);
    this.currentLang = this.languagesList.find(item => item.langCode === langCode);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(langCode));
  }

  getLanguages(): Language[] {
    return languages;
  }

  getActualLanguageCode(): string {
    return JSON.parse(localStorage.getItem(this.STORAGE_KEY));
  }

  getActualLanguageCodeBack(): string {
    return JSON.parse(localStorage.getItem(this.STORAGE_KEY_BACK));
  }

  setActualLanguageCodeBack(languageCode: string): void {
    localStorage.setItem(this.STORAGE_KEY_BACK, JSON.stringify(languageCode));
  }



  private initializeTranslations(): void {
    if (!this.auth.isAuthorized()) {
      // Use default languages for unauthorized users
      this.translate.setDefaultLang(this.DEFAULT_LANGUAGE_CODE);
      const languagesList = this.languagesList.map(lang => lang.langCode);
      this.translate.addLangs(languagesList);
    } else {
      // Fetch translations only once for authorized users
      const queryParams: QueryParamsTranslation = {
        scopes: [TranslationScope.Front, TranslationScope.Back],
        widget: 'fk',
        no_limits: true
      };
      this.languagesSettingsService.getTranslations(queryParams).subscribe((values) => {
        const defaultLanguage = values.find(i => i.is_default && i.scope === TranslationScope.Front)?.language_code || this.DEFAULT_LANGUAGE_CODE;
        const defaultLanguageBack = values.find(i => i.is_default && i.scope === TranslationScope.Back)?.language_code;

        if (defaultLanguageBack) {
          this.setActualLanguageCodeBack(defaultLanguageBack);
        }
        this.translate.setDefaultLang(defaultLanguage);
  
        this.languagesList = values.map(i => ({ name: i.language_name, langCode: i.language_code }));
        this.translate.addLangs(this.languagesList.map(lang => lang.langCode));
      }, (error) => this.notificationsService.showError(error));
    }
  }

  private getSavedLanguage(): void {
    let languageCode = this.getActualLanguageCode();
    if (!languageCode) {
      const browserLang = this.translate.getBrowserCultureLang().toLowerCase();
      languageCode = this.languagesList.find(language => language.langCode === browserLang)?.langCode || this.DEFAULT_LANGUAGE_CODE;
    }
    if (languageCode && this.auth.isAuthorized()) {
      this.switchLanguage(languageCode);
    }
  }

  public loadTranslation(lang: string = this.DEFAULT_LANGUAGE_CODE): void {
    const queryParams: QueryParamsTranslation = {
      scope: TranslationScope.Front
    };
    const loader = new CustomTranslateLoader(
      this.http,
      queryParams
    );

    // Force the service to reload translations
    loader.getTranslation(lang).subscribe((translations) => {
      // Update the TranslateService with new translations
      this.translate.setTranslation(lang, translations, true);
      this.switchLanguage(lang);
    }, (error) => this.notificationsService.showError(error));
  }
}
