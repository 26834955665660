import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

import { VisitsService } from '@app/core/services/visits/visits.service';
import { EditVisitData } from '@app/models/visits/edit-visit-data.model';
import { NotificationsService, TranslationData, VisitDetailsService } from '@app/core/services';
import { ResponseResult } from '@app/models/response.model';
import { VisitWindowsService } from '@app/pages/visit-floating-windows/visit-windows.service';

@UntilDestroy()
@Component({
  selector: 'app-visit-notifications',
  templateUrl: './visit-notifications.component.html',
  styleUrls: ['./visit-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitNotificationsComponent implements OnInit {

  @Input() visits: EditVisitData[] = [];

  constructor(
    private visitWindowsService: VisitWindowsService,
    private visitsService: VisitsService,
    private visitDetailsService: VisitDetailsService,
    private notificationsService: NotificationsService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.visitsService.recentlyCreatedVisits
      .pipe(untilDestroyed(this))
      .subscribe((value: EditVisitData) => {
        this.visits.push(value);
        this.visits = this.visits.sort((a: EditVisitData, b: EditVisitData) => a.id > b.id ? -1 : 1);
        if (this.visits.length > 5) {
          this.visits = this.visits.filter((visit: EditVisitData, index: number) => index !== this.visits.length - 1);
        }
        this.cd.detectChanges();
    });
  }

  openVisit(visitId: number): void {
    this.visits = this.visits.filter(visit => visit.id !== visitId);
    this.visitWindowsService.openWindow(visitId);
  }

  blastVisit(visit: EditVisitData): void {
    this.visitDetailsService.createVisitBlasting({ visit: visit.id, caregivers: [] })
      .pipe(
        finalize(() => this.cd.detectChanges()),
        untilDestroyed(this),
      ).subscribe((response: ResponseResult) => {
        this.visits = this.visits.filter(i => i.id !== visit.id);
        this.notificationsService.showSuccess(
          new TranslationData('visitNotifications.toastr_visitWasBlasted', { visitId: visit.number })
        );
      }, (error: HttpErrorResponse) => this.notificationsService.showError(error));
  }

  getVisitTime(startTime: string, endTime: string): string {
    const start = moment(startTime);
    const end = moment(endTime);

    return `${ start.format('hh:mm A') } - ${ end.format('hh:mm A') }`;
  }

  close(visitId: number): void {
    this.visits = this.visits.filter(visit => visit.id !== visitId);
  }
}
