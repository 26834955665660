import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { Resume } from '@app/models/hr/resume.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {

  constructor(
    private http: HttpService
  ) { }

  getResume(params?: object): Observable<PaginatedResponse> {
    return this.http.GET(URLS.resume, params);
  }

  uploadResume(data): Observable<Resume> {
    return this.http.POST(URLS.resume, data);
  }

  deleteResume(id: number): Observable<unknown> {
    return this.http.DELETE(`${ URLS.resume }${ id }/`);
  }

  downloadResumeById(id: number): Observable<Resume> {
    return this.http.GET(`${ URLS.resume }${ id }/`);
  }
}
