import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  PaymentPayerDetails,
  PaymentPayerDownloadDetails,
  PaymentPayerGenerateInvoices,
  MakePaymentPayload,
  PaymentPayerHistory,
  PaymentPayerInvoice,
  PaymentPayerInvoiceBatch,
  PaymentPayerInvoiceClaim,
  PaymentPayerPrivateClaim,
  PaymentPayerPrivateHistory,
  PaymentPayerPrivateInvoiceVisit,
  PaymentPayerReport
} from '@app/models/payer/private-pay.model';
import { VisitHistory } from '@app/models/patient/visit-details/history.model';

@Injectable({
  providedIn: 'root'
})
export class PrivatePayService {
  private refreshPage = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpService
  ) { }

  get getRefreshPage(): Observable<boolean> {
    return this.refreshPage.asObservable();
  }

  set setRefreshPage(value: boolean) {
    this.refreshPage.next(value);
  }

  getPaymentPayerInvoiceBatchInfo(batchId: string, query: string): Observable<PaymentPayerInvoiceBatch> {
    return this.http.GET<PaymentPayerInvoiceBatch>(`${ URLS.payer_invoice_batch }${ batchId }/${ query }`);
  }

  getPaymentPayerInvoiceClaim(params?: object): Observable<PaginatedResponse<PaymentPayerInvoiceClaim>> {
    return this.http.GET<PaginatedResponse<PaymentPayerInvoiceClaim>>(URLS.payer_invoice_claim, params);
  }

  getPaymentPayerInvoiceNestedDetails(claimId: number, query: string): Observable<PaymentPayerDetails> {
    return this.http.GET<PaymentPayerDetails>(`${ URLS.payer_invoice_claim }${ claimId }/${ query }`);
  }

  getPaymentPayerPrivateHistory(claimId: number, limit: number = 1000): Observable<PaginatedResponse<PaymentPayerPrivateHistory>> {
    return this.http.GET<PaginatedResponse<PaymentPayerPrivateHistory>>(`${ URLS.payer_private_payment }?claim_id=${ claimId }&limit=${ limit }`);
  }

  makePayerPrivatePayment(body: MakePaymentPayload): Observable<PaymentPayerPrivateClaim> {
    return this.http.POST<PaymentPayerPrivateClaim>(URLS.payer_private_payment, body);
  }

  getPatientGroupInvoices(claimId: number, query: string): Observable<PaymentPayerInvoice[]> {
    return this.http.GET<PaymentPayerInvoice[]>(`${ URLS.payer_invoice_claim }${ claimId }/patient_group_invoices/?${ query }`);
  }

  setVoidPayment(paymentId: number, body: { status: number }): Observable<{ status: number }> {
    return this.http.PUT<{ status: number }>(`${ URLS.payer_private_payment }${ paymentId }/set_void/`, body);
  }

  privatePayGenerateInvoice(claimId: number, query: string, body): Observable<PaymentPayerGenerateInvoices> {
    return this.http.POST<PaymentPayerGenerateInvoices>(`${ URLS.payer_invoice_claim }${ claimId }/private_pay_generate_invoice/?${ query }`, body);
  }

  changeHistoryPayment(paymentId: number, body: PaymentPayerHistory): Observable<PaymentPayerHistory> {
    return this.http.PUT<PaymentPayerHistory>(`${ URLS.payer_private_payment }${ paymentId }/`, body);
  }

  getPrivatePaymentReport(claimId: number, query: string): Observable<PaymentPayerReport> {
    return this.http.GET<PaymentPayerReport>(`${ URLS.payer_invoice_claim }${ claimId }/private_payment_report/?${ query }`);
  }

  getPrivatePaymentDownloadInfo(claimId: number, query: string): Observable<PaymentPayerDownloadDetails> {
    return this.http.GET<PaymentPayerDownloadDetails>(`${ URLS.payer_invoice_claim }${ claimId }/private_payment_download_info/?${ query }`);
  }

  changePrivatePaymentDownloadInfo(claimId: number, query: string, body): Observable<PaymentPayerDownloadDetails> {
    return this.http.PUT<PaymentPayerDownloadDetails>(`${ URLS.payer_invoice_claim }${ claimId }/private_payment_download_info/?${ query }`, body);
  }

  getPrivatePaymentHistory(visitId: number, query: string): Observable<PaginatedResponse<VisitHistory>> {
    return this.http.GET<PaginatedResponse<VisitHistory>>(`${ URLS.history }private_pay_claim/${ visitId }/?${ query }`);
  }

  getPrivatePaymentInvoices(claimId: number, query: string): Observable<PaymentPayerPrivateInvoiceVisit[]> {
    return this.http.GET<PaymentPayerPrivateInvoiceVisit[]>(`${ URLS.payer_invoice_claim }${ claimId }/private_payment_invoices/?${ query }`);
  }
}
