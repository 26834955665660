import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services/http/http.service';
import { URLS } from '@app/shared/urls';
import { DatePeriod, DatePeriodParams } from '@app/models/scheduling/date-period.model';
import { Scheduling, SchedulingParams } from '@app/models/scheduling/scheduling.model';
import { SchedulingStatisticSettings, SchedulingUserFilter } from '@app/models/scheduling/scheduling-statistic.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService {

  constructor(
    private http: HttpService
  ) { }

  getDatePeriods(params: DatePeriodParams): Observable<DatePeriod[]> {
    return this.http.GET<DatePeriod[]>(URLS.date_periods, params);
  }

  getSchedulingStatistic(params: SchedulingParams): Observable<SchedulingStatisticSettings<number | string>> {
    return this.http.GET<SchedulingStatisticSettings<number | string>>(`${ URLS.visit_weekly_scheduling }statistic/`, params);
  }

  getSchedulingStatisticFilter(): Observable<SchedulingUserFilter> {
    return this.http.GET<SchedulingUserFilter>(`${ URLS.visit_weekly_scheduling }user_filter/`);
  }

  updateSchedulingStatistic(data: SchedulingUserFilter): Observable<SchedulingUserFilter> {
    return this.http.PUT<SchedulingUserFilter>(`${ URLS.visit_weekly_scheduling }user_filter/`, data);
  }

  getScheduling(params: object): Observable<PaginatedResponse<Scheduling>> {
    return this.http.GET<PaginatedResponse<Scheduling>>(`${ URLS.visit_weekly_scheduling }`, params);
  }
}
