import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { VacancyPayload, Vacancy } from '@app/models/hr/vacancy.model';

@Injectable({
  providedIn: 'root'
})
export class VacanciesService {

  constructor(
    private http: HttpService
  ) { }

  addVacancy(data: VacancyPayload): Observable<Vacancy> {
    return this.http.POST<Vacancy>(URLS.vacancy, data);
  }

  deleteVacancy(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.vacancy }${ id }/`);
  }

  editVacancy(vacancyId: number, vacancy: VacancyPayload): Observable<Vacancy> {
    return this.http.PUT<Vacancy>(`${ URLS.vacancy }${ vacancyId }/`, vacancy);
  }

  getVacancyById(url: string): Observable<Vacancy> {
    return this.http.GET<Vacancy>(url);
  }
}
