<div class="editor-panel">
  <div class="editor-panel__item top-info" *ngIf="identifier">
    <app-chip
      *ngIf="identifier"
      [value]="identifier.value"
      [status]="identifier.status">
    </app-chip>
  </div>

  <div class="editor-panel__item title" *ngIf="titleWithoutCard">
    {{ titleWithoutCard }}
  </div>

  <div class="editor-panel__item user-card" *ngIf="editorInfoCard">
    <app-panel-editor-info-card
      [editorInfoCard]="editorInfoCard"
      [Enum]="Enum"
      [idTitle]="idTitle">
    </app-panel-editor-info-card>
  </div>
    
  <app-panel-editor-menu
    class="editor-panel__item"
    [menuNodes]="menuNodes"
    [nodesWithNotifications]="menuNodesWithNotifications">
  </app-panel-editor-menu>
</div>