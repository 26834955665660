import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, LanguageService } from '@app/core/services';


@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  private readonly defaultLanguage = 'en';

  constructor(
    private authService: AuthService,
    private languageService: LanguageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let acceptLanguage = this.defaultLanguage; 

    if (this.authService.isAuthorized()) {
      const companyId = this.authService.profile.companyId;
      const languageCode = this.languageService.getActualLanguageCodeBack();

      if (companyId && languageCode) {
        if (languageCode === this.defaultLanguage) {
          acceptLanguage = languageCode;
        } else {
          acceptLanguage = `${ companyId }_${ languageCode }`;
        }
      }
    }

    const clonedRequest = req.clone({
      setHeaders: {
        'Accept-Language': acceptLanguage,
      },
    });

    return next.handle(clonedRequest);
  }
}
