import { OfficeWidget, PayerWidget, UserWidget } from '@app/models/widgets.model';
import { PaginationParams } from '@app/shared/interfaces/pagination.class';
import { PatientPriorityLevel } from '@app/core/enums';
import { MaritalStatus } from '../users/edit-user-data.model';
import { GenderType } from '../gender.model';
import { EthnicityType, MasterweekStatus, PatientType, RaceType } from '@app/core/enums';
import { AuthorizationStatus } from './authorizations.model';
import { AssignVisitStatus } from '../available-masterweeks/available-masterweeks.model';

export interface Patient {
  id: number;
  number: string;
  avatar: string;
  full_name: string;
  birthday: string;
  phone_number_info: {
    number: string;
  };
  address_info: AddressInfo;
  basic_services_details: BasicServiceDetail[];
  nurse_service_detail: any;
  office_detail: OfficeWidget;
  payers?: string;
  payers_details: PayerWidget[];
  nurse_detail: UserWidget;
  coordinators_details: UserWidget[];
  status: PatientStatus;
  status_value: string;
  priority_level: PatientPriorityLevel;
}

interface BasicServiceDetail {
  id: number;
  name: string;
  is_nurse: boolean;
}

interface AddressInfo {
  id: number;
  address_detail: AddressDetail;
}

interface AddressDetail {
  id: number;
  full_address: string;
}

export interface PatientAttentions {
  master_week_blasting_count: number;
}

export enum PatientStatus {
  Active,
  Hold,
  Discharged,
  HospitalizedObservation,
  HospitalizedAdmitted,
  PatientNeverStarted,
  Inactive,
}

export interface PatientsRequestParams extends PaginationParams {
  widget?: string;
  statuses?: PatientStatus[];
  lookup_field?: string;
  search?: string;
  disciplines?: number[];
  payers?: number[];
  coordinators?: number[];
  offices?: number[];
  marital_statuses?: MaritalStatus[];
  gender?: GenderType[];
  races?: RaceType[];
  ethnicities?: EthnicityType[];
  primary_languages?: number[];
  patient_type?: PatientType[];
  authorization_statuses?: AuthorizationStatus[];
  masterweek_statuses?: MasterweekStatus[];
  masterweek_assign_statuses?: AssignVisitStatus[];
  no_limits?: boolean;
  priority_level?: PatientPriorityLevel[];
}
